import Media from 'src/utils/media';
import styled from 'styled-components';

export const InterpretationStyle = styled.div`
  .scroll-wrapper {
    height: 100%;
    width: 100%;
    .content-consultation {
      height: 100%;
      width: 100%;
      padding: 0px 10px;
      &::-webkit-scrollbar {
        width: 5px;
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #745811;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #715e2d;
      }
    }
  }
  .card-list {
    .ant-card-body {
      height: calc(100vh - 220px);
      padding: 10px 0px;
      ${Media.lessThan(Media.SIZE.MD)} {
        height: calc(50vh - 110px);
      }
    }
  }
  .content-right {
    border-left: 1px solid #4e473e;
  }

  .btn-submit {
    display: flex;
    justify-content: flex-end;
  }

  .text-white {
    font-size: 16px;
    color: #ffffff;
  }
  .custom-card {
    .ant-card-body {
      padding: 0px;
    }
  }
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

