/* eslint-disable prefer-const */
const createStorage = storage => {
  const get = (key, defaultValue = null) => {
    let value = storage?.getItem(key) ?? defaultValue;

    if (value && typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch (error) {
        return defaultValue;
      }
    }
    return value;
  };

  const has = key => {
    return get(key) !== null;
  };

  const set = (key, value) => {
    const serializedValue = JSON.stringify(value);
    storage?.setItem(key, serializedValue);
  };

  const remove = key => {
    storage?.removeItem(key);
  };

  const clear = () => {
    storage?.clear();
  };

  const pull = (key, defaultValue = null) => {
    const value = get(key, defaultValue);
    remove(key);
    return value;
  };

  return { get, has, set, remove, clear, pull };
};

const LocalStorage = createStorage(window.localStorage);
const SessionStorage = createStorage(window.sessionStorage);

export default LocalStorage;
export { SessionStorage };

