import { createSlice } from '@reduxjs/toolkit';
import { getMeAction, loginAction, registerAction, uploadFileAction, verificationMailAction, resetPasswordAction } from './auth.action';

const initialState: Auth.LoginState = {
  isLoading: false,
  error: null,
  meInfo: null,
};

const { actions, reducer } = createSlice({
  name: 'auth_slice',
  initialState,
  reducers: {
    resetMeInfo: (state) => {
      state.meInfo = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loginAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meInfo = action.payload;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(registerAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(registerAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(registerAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getMeAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMeAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getMeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(uploadFileAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(uploadFileAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(uploadFileAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(verificationMailAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verificationMailAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(verificationMailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(resetPasswordAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(resetPasswordAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
  },
});

export { reducer };
export default actions;
