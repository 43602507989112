import React from 'react';
import { TimeLineStyleStyle } from './TimeLineStyle';
import { getTimeByKey } from 'src/utils';
import { getStatusByKey } from 'src/utils/common';

interface TimelineProps {
  title?: string;
  requestTime?: string;
  requestStatus?: string;
  isRepeated?: number;
}

const TimeLine: React.FC<TimelineProps> = ({ title, requestTime, requestStatus, isRepeated }) => {
  const renderStatusAppointment = (value: string) => {
    if (value === 'pending') return <span className="pending">{getStatusByKey(value)}</span>
    if (value === 'approve') return <span className="approve">{getStatusByKey(value)}</span>
    if (value === 'reject') return <span className="reject">{getStatusByKey(value)}</span>
  }

  return (
    <TimeLineStyleStyle>
      {title && <div className="title"> {title} </div>}
      <div className="wrapper-content">
        <div className="date-time">
          {requestTime && <div className="date"> {getTimeByKey(requestTime)} </div>}
          {requestStatus && <div className="label-day"> {isRepeated ? 'Hằng Năm' : 'Ngày'} </div>}
        </div>
        {requestStatus && <div className="status"> {renderStatusAppointment(requestStatus)} </div>}
      </div>
    </TimeLineStyleStyle>
  );
};

export default TimeLine;
