import { createSlice } from '@reduxjs/toolkit';
import { changePasswordAction, getUserAction, updateUserAction } from './user.action';

const initialState: User.UserState = {
  isLoading: false,
  error: null,
  user: null,
};

const { actions, reducer } = createSlice({
  name: 'user_slice',
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUserAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(getUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(updateUserAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(changePasswordAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changePasswordAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(changePasswordAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export { reducer };
export default actions;
