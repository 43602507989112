/* eslint-disable no-console */
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CardStyle } from 'src/utils/styled';
import { Col, Row, Skeleton } from 'antd';
import { InterpretationStyle } from './ConsultationStyle';
import ChatSection from './chats/ChatSection';
import ChatDetail from './chats/ChatDetail';
import { SelectCustom } from 'src/components/form';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { getApprovedRequestAction, getConsultationsAction } from 'src/stores/screens/consultation/consultation.action';
import consultationReducer from 'src/stores/screens/consultation/consultation.reducer';
import { formatVietnameseDate, getTimeByKey } from 'src/utils';
import { useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';


const ConsultationScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { consultations } = useAppSelector(state => state.consultation);
  const infiniteSessionRef = useRef(null);
  const [filterApprove, setFilterApprove] = useState<number | string>()

  const { detailId } = useParams();
  const appointmentId = Number(detailId);

  const navigate = useNavigate();

  const handldeOnchange = (_filed: string, value: number | string) => {
    setFilterApprove(value)
    void getApprovedRequest({ page: 1, isRepeated: value === 'all' ? null : Number(value), limit: 20 });
  };

  const getApprovedRequest = async (params: any) => {
    await dispatch(
      getApprovedRequestAction({ params: { ...params, limit: 20 } }),
    );
  };

  const getConsultationDetail = useCallback(async (id) => {
    await dispatch(getConsultationsAction({ id }));
  }, [dispatch]);

  useEffect(() => {
    let intervalId;
    const fetchData = () => {
      if (appointmentId) {
        void getConsultationDetail(appointmentId);
      }
    };
    fetchData();
    if (appointmentId) {
      intervalId = setInterval(fetchData, 30000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [appointmentId, getConsultationDetail]);

  useEffect(() => {
    void getApprovedRequest({ page: 1, limit: 20 });
    return () => {
      dispatch(consultationReducer.resetConsultations());
    }
  }, []);

  const consultationClick = (item: Consultation.Appointment) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    let displayItem = item?.appointments[0];

    for (const appointment of item?.appointments) {
      const appointmentMonth = appointment?.repeatTime ? new Date(appointment.repeatTime).getMonth() + 1 : null;
      if (appointmentMonth === currentMonth) {
        displayItem = appointment;
        break;
      }
    }
    navigate(`/consultation/${item?.id}/${displayItem?.id}`);
  };

  const renderHeader = () => {
    const info = consultations?.[0];
    return (
      <div>
        Luận giải {info?.requestDate ? '-' : ''}
        {info?.requestDate ? formatVietnameseDate(info?.requestDate) : ''} {' '}
        {info?.requestTime ? getTimeByKey(info?.requestTime) : ''}
      </div>
    );
  };

  const loadMoreData = async () => {
    await getApprovedRequest({ page: Number(consultations.page) + 1, isRepeated: filterApprove === 'all' ? null : Number(filterApprove), limit: 20 });
  };

  return (
    <InterpretationStyle className="container">
      <Row gutter={[24, 16]}>
        <Col sm={24} md={24} lg={8} span={24}>
          <CardStyle
            className="card-list"
            title={
              <div className="title-wrapper">
                <div>Danh sách</div>
                <SelectCustom
                  field="date-time"
                  optionsSelect={[
                    { label: 'Tất cả', value: 'all' },
                    { label: 'Năm', value: 1 },
                    { label: 'Ngày', value: 0 },
                  ]}
                  setFieldValue={handldeOnchange}
                  defaultValue={'all'}
                />
              </div>
            }
          >
            <div className="scroll-wrapper">
              <div
                className="content-consultation"
                id="scrollDiv"
                style={{
                  overflow: 'auto',
                }}
              >
                <InfiniteScroll
                  ref={infiniteSessionRef}
                  dataLength={consultations?.data.length}
                  next={loadMoreData}
                  hasMore={consultations?.data.length < consultations?.total}
                  loader={<Skeleton paragraph={{ rows: 1 }} />}
                  endMessage={null}
                  scrollableTarget="scrollDiv"
                >
                  {_.map(consultations.data, (product: Consultation.Appointment) => (
                    <div key={product.id} onClick={() => consultationClick(product)}>
                      <ChatSection message={product} />
                      <hr />
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          </CardStyle>
        </Col>
        <Col sm={24} md={24} lg={16} span={24}>
          {/* <NoAppointment requestDate='Ghi chú' content='Chưa có ghi chú nào được ghim ra ngoài'/> */}
          <CardStyle title={renderHeader()} className="custom-card">
            <ChatDetail />
          </CardStyle>
        </Col>
      </Row>
    </InterpretationStyle>
  );
};

export default ConsultationScreen;
