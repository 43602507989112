import { createSlice } from '@reduxjs/toolkit';
import { busyTimeAction } from './common.action';

const initialState: Common.BusyTimeState = {
  isLoading: false,
  error: null,
  busyTime: [],
};

const { actions, reducer } = createSlice({
  name: 'common_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(busyTimeAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(busyTimeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.busyTime = action.payload?.data ?? [];
      })
      .addCase(busyTimeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
  },
});

export { reducer };
export default actions;
