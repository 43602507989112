import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const AUTH_API = {
  loginAPI: async (params: Auth.LoginRequestData) => {
    const response = await new AxiosClient().post('/auth/login', params);
    const data = get(response, 'data', null);
    return data;
  },

  registerAPI: async (params: Auth.LoginRequestData) => {
    const response = await new AxiosClient().post('/auth/register', params);
    const data = get(response, 'data', null);
    return data;
  },

  getMeAPI: async (userId: string) => {
    const response = await new AxiosClient().get(`/user/${userId}`);
    const data = get(response, 'data', null);
    return data;
  },

  uploadFileAPI: async (params: any) => {
    const response = await new AxiosClient().post('/user/avatar', params);
    const data = get(response, 'data', null);
    return data;
  },

  verificationCodeAPI: async (params: any) => {
    const response = await new AxiosClient().post('/user/send-verification-code', params);
    const data = get(response, 'data', null);
    return data;
  },

  resetPasswordAPI: async (params: any) => {
    const response = await new AxiosClient().post('/user/reset-password', params);
    const data = get(response, 'data', null);
    return data;
  },
};

export default AUTH_API;
