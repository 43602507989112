import React from 'react';
import { TagCustomStyle } from './tagStyle';

interface timeComponentProps {
  children?: any;
  color?: string;
  className?: string;
  style?: any
}

const TagCustom: React.FC<timeComponentProps> = ({
  children,
  className,
  style,
  ...props
}) => {
  return (
    <TagCustomStyle className={className} {...props} style={style}>{children}</TagCustomStyle>
  );
};

export default TagCustom;
