import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const APPOINTMENT_API = {
  createAppointmentAPI: async (params: Appointment.AppointmentRequestData) => {
    const response = await new AxiosClient().post('/appointment/request', params);
    const data = get(response, 'data', null);
    return data;
  },

  getAppointmentAPI: async (params: Appointment.AppointmentRequestData) => {
    const response = await new AxiosClient().get('/appointment/user-requests', { params });
    const data = get(response, 'data', null);
    return data;
  },

  deleteAppointmentAPI: async (params: { id: string }) => {
    const response = await new AxiosClient().delete(`/appointment/request/${params?.id ?? ''}`);
    const data = get(response, 'data', null);
    return data;
  },
};

export default APPOINTMENT_API;
