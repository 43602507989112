import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');

export const formatDateDDMMYYYY = (date: string) => {
  if (!date) return '';
  return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
};

export const formatDateYYYYMMDD = (date: string) => {
  if (!date) return '';
  return moment(date, 'DD-MM-YYYY')?.format('YYYY-MM-DD');
};

// convert from 2024-10-03 to Thứ năm, 03/10/2024
export const formatVietnameseDate = (date: string) => {
  const nameDate = moment(date).format('dddd');
  const vietnameseDate = nameDate.charAt(0).toUpperCase() + nameDate.slice(1);
  const formatDatedate = moment(date).format('DD/MM/YYYY');
  return `${vietnameseDate}, ${formatDatedate}`;
};

export const formatVietnameseMonth = (date: string) => {
  const formatDatedate = moment(date).format('MM/YYYY');
  return `${formatDatedate}`;
};

// convert 2024-10-10T06:28:28.451Z to 10 - 10 - 2024 13:28
// if date == currentDate show 13:28
export const dateTimeForChat = (dateString: string) => {
  const momentDate = moment(dateString);
  const today = moment();
  if (momentDate.isSame(today, 'day')) {
    return momentDate.format('HH:mm');
  } else {
    return momentDate.format('DD-MM-YY HH:mm');
  }
};
