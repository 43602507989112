import React from 'react';
import type { RouteObject } from 'react-router-dom';

import { PrivateLayout } from 'src/layouts';
import { NotFound } from 'src/screens/NotFound';
import { AppointmentScreen, DashboardScreen, ConsultationScreen, SettingScreen, DetailNewScreen } from 'src/screens/privateScreens';

// const DashboardScreen = React.lazy(
//   async () => await import('src/screens/privateScreens').then(module => ({ default: module.DashboardScreen })),
// );
// const AppointmentScreen = React.lazy(
//   async () => await import('src/screens/privateScreens').then(module => ({ default: module.AppointmentScreen })),
// );
// const ConsultationScreen = React.lazy(
//   async () => await import('src/screens/privateScreens').then(module => ({ default: module.ConsultationScreen })),
// );
// const SettingScreen = React.lazy(
//   async () => await import('src/screens/privateScreens').then(module => ({ default: module.SettingScreen })),
// );

// const NotFoundScreen = React.lazy(
//   async () => await import('src/screens/NotFound').then(module => ({ default: module.NotFound })),
// );

const _privateRoutes: RouteObject[] = [
  {
    element: <PrivateLayout />,
    children: [
      { path: '/', element: <DashboardScreen /> },
      { path: '/dashboard', element: <DashboardScreen /> },
      { path: '/appointment', element: <AppointmentScreen /> },
      { path: '/consultation', element: <ConsultationScreen /> },
      { path: '/consultation/:id/:detailId', element: <ConsultationScreen /> },
      { path: '/setting', element: <SettingScreen /> },
      { path: '/detail-news/:id', element: <DetailNewScreen /> },
      { element: <NotFound />, path: '*' },
    ],
  },
];

export default _privateRoutes;
