import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import consultationReducer from 'src/stores/screens/consultation/consultation.reducer';

const ResetStateReducer: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(consultationReducer.resetMessages());
  }, [location.pathname, dispatch]);

  return null;
};

export default ResetStateReducer;
