export const TIME_LINE = [
  { key: 'frame_01', value: '08:00 - 10:00' },
  { key: 'frame_02', value: '10:00 - 12:00' },
  { key: 'frame_03', value: '14:00 - 16:00' },
  { key: 'frame_04', value: '16:00 - 18:00' },
  { key: 'frame_05', value: '19:30 - 21:30' },
]

export const APPOINTMENT_STATUS = [
  { key: 'pending', value: 'Chờ xác nhận' },
  { key: 'approve', value: 'Đã xác nhận' },
  { key: 'reject', value: 'Đã từ chối' },
];
