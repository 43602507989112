import React from 'react';
import { Button as ButtonAnt } from 'antd';
import styled from 'styled-components';

type ButtonVariant = 'submit' | 'cancel' | 'default' | 'none' | 'icon';

interface CommonButtonProps {
  buttonType?: ButtonVariant;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  width?: number;
  disabled?: boolean;
  loading?: boolean;
}

const StyledButton = styled(ButtonAnt) <{ buttonType: ButtonVariant; width?: number }>`
  &.ant-btn {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    align-items: center;
    display: flex;
    padding: 21px;
    justify-content: center;
    ${props =>
      props.buttonType === 'submit' &&
      `
      background-color: #1890ff;
      color: white;
      border: none;
    `}
    ${props =>
      props.buttonType === 'none' &&
      `
      background-color: rgb(34, 32, 30);
      color: #ead38e;
      border: 1px solid #ead38e;
    `}
    ${props =>
      props.buttonType === 'cancel' &&
      `
      background-color: #ff4d4f;
      color: white;
      border: none;
    `}
    ${props =>
      props.buttonType === 'icon' &&
      `
      padding: 4px;
      margin: 16px;
      background-color: unset;
      color: unset;
      border: none;
    `}
    ${props =>
      props.buttonType === 'default' &&
      `
      background: #ead38e;
      color: black;
      border: none;
    `}
    &:disabled {
      ${(props) =>
      props.buttonType === 'default' &&
      `
        background: #867956;
        color: #2B2622;
        border: none;
      `}
    }

    &:hover {
      opacity: 0.9;
    }
    &.btn-right {
      float: right;
    }
    &.btn-submit {
      margin-top: 20px;
    }
    width: ${props => props.width ? `${props.width}px` : 'auto'};
  }
`;

const Button: React.FC<CommonButtonProps> = ({
  buttonType = 'default',
  onClick,
  children,
  className,
  htmlType = 'button',
  width,
  disabled,
  loading,
  ...props
}) => {
  return (
    <StyledButton buttonType={buttonType} onClick={onClick} className={className} htmlType={htmlType} width={width} disabled={disabled} loading={loading} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
