import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const CONSULTATION_API = {
  getConsultationsAPI: async (id: number, params: any) => {
    const response = await new AxiosClient().get(`/consultation/appointment/${String(id)}`, { params });
    const data = get(response, 'data', null);
    return data;
  },

  getApprovedRequestAPI: async (params: any) => {
    const response = await new AxiosClient().get('/appointment/approved-requests', { params });
    const data = get(response, 'data', null);
    return data;
  },

  createConsultationAPI: async (params: any) => {
    const response = await new AxiosClient().post('/consultation', params);
    const data = get(response, 'data', null);
    return data;
  },

  pinConsultationAPI: async (params: any) => {
    const response = await new AxiosClient().put(`/consultation/${params?.id}`, { pinned: params.pinned });
    const data = get(response, 'data', null);
    return data;
  },
};

export default CONSULTATION_API;
