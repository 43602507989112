import { Card } from 'antd';
import Media from 'src/utils/media';
import styled from 'styled-components';

export const CardDashboardStyle = styled(Card)`
  border: 1px solid rgba(116, 88, 18, 1);
  border-radius: 8px;
  width: 100%;
  background: rgba(34, 32, 30, 1);
  height: 100%;
  .ant-card-body {
    height: calc(100vh - 220px);
    overflow: auto;
    ${Media.lessThan(Media.SIZE.MD)} {
      height: calc(50vh - 110px);
    }
  }
  .ant-card-head {
    background: radial-gradient(66.3% 39.31% at 50% 5%, rgba(213, 153, 7, 0.49) 0%, rgba(0, 0, 0, 0.2) 100%), #12100f;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25), inset 0px 5px 6px 1px #000000;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .ant-card-head-title {
      font-weight: 500;
      font-size: 18px;
      color: #efd485;
      .title {
        display: flex;
        gap: 16px;
        .ant-image {
          cursor: pointer;
        }
      }
    }
  }
  .ant-card-body {
    //max-height: 660px;
    //overflow: auto;
    &::-webkit-scrollbar {
      width: 3px;
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #745811;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #715e2d;
    }
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    background: rgba(34, 32, 30, 1);
  }
  &&:has(.ant-card-head) .ant-card-body {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

export const CardStylePined = styled(Card)`
  border: 1px solid rgba(116, 88, 18, 1);
  border-radius: 8px;
  width: 100%;
  background: rgba(34, 32, 30, 1);
  .ant-card-body {
    height: calc((100vh - 220px - 285px));
    overflow: auto;
    ${Media.lessThan(Media.SIZE.MD)} {
      height: calc(50vh - 110px - 100px);
    }
  }
  .ant-card-head {
    background: radial-gradient(66.3% 39.31% at 50% 5%, rgba(213, 153, 7, 0.49) 0%, rgba(0, 0, 0, 0.2) 100%), #12100f;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25), inset 0px 5px 6px 1px #000000;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .ant-card-head-title {
      font-weight: 500;
      font-size: 18px;
      color: #efd485;
      .title {
        display: flex;
        gap: 16px;
        .ant-image {
          cursor: pointer;
        }
      }
    }
  }
  .ant-card-body {
    //max-height: 660px;
    //overflow: auto;
    &::-webkit-scrollbar {
      width: 3px;
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #745811;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #715e2d;
    }
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    background: rgba(34, 32, 30, 1);
  }
  &&:has(.ant-card-head) .ant-card-body {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

export const AppointmentStyle = styled.div`
  /* .ant-card-body {
    max-height: 660px;
    overflow: auto;
  } */
  .custom-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .custom-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .block-cal {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid rgb(116, 88, 18);
    border-radius: 8px;
    position: relative;
    .img-style {
      width: 60px;
      height: 60px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background: #EAD38E;
      position: relative;
      .ant-image {
        position: absolute;
        top: 16px;
        left: 14px;
      }
    }
    .text-date {
      margin-left: 16px;
      color: #ffffff;
    }
    .bock-status1 {//đã cung cấp tài liệu
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #D06E14;
      background: #361401;
      position: absolute;
      right: 24px;
      color: #D06E14;
    }
    .bock-status2 { //đã cung cấp thông tin
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #1961EE;
      background: #011D36;
      position: absolute;
      right: 24px;
      color: #1961EE;
    }
    .bock-status3 { // đã tư cấn
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #1961EE;
      background: #023601;
      position: absolute;
      right: 24px;
      color: #38A107;
    }
    .bock-status4 { //hoàn thành
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #38A107;
      background: #023601;
      position: absolute;
      right: 24px;
      color: #38A107;
    }
    .bock-status5 { //KHỞI TẠO
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #ead38a;
      background: #594105;
      position: absolute;
      right: 24px;
      color: #ead38a;
    }
    .bock-status6 { //chờ xác nhân
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #8EEAEA;
      background: #024141;
      position: absolute;
      right: 24px;
      color: #8EEAEA;
    }
  }
  .content-right {
    border-left: 1px solid #4e473e;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-submit {
    display: flex;
    justify-content: flex-end;
  }

  .text-white {
    font-size: 16px;
    color: #ffffff;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .appointment-year {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
  }
  .form-all-year {
    margin-bottom: 0px;
  }
`;

export const CalendarContentStyle = styled.div`
  .title {
    font-size: 16px;
    color: #ead38e;
  }
  .description {
    font-size: 14px;
    color: #ffffff;
  }
`;

export const CalendarStyle = styled.div`
  .custom-calendar {
    background: transparent;
    font-size: 16px;
    width: 100%;
    border: unset;
  }
  .react-calendar__year-view__months {
    color: #ffffff;
  }
  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: unset;
    }
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: unset;
  }

  .react-calendar__navigation {
    color: #ead38e;
  }

  .react-calendar__month-view__weekdays,
  .react-calendar__month-view__days__day {
    color: #ffffff;
    font-size: 16px;
  }

  .react-calendar__tile {
    background: transparent;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
  }

  .react-calendar__tile--now {
    color: yellow;
    background: transparent;
    font-weight: bold;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar button:enabled:hover {
    background: none;
    background-color: unset;
    position: relative;
    line-height: 17px;

    abbr {
      background: #ead38e;
      color: #342c24;
      border-radius: 50%;
      border: 1px solid #52c41a;
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .react-calendar__month-view__days__day {
    height: 50px;
    width: 50px;
  }

  .react-calendar__tile:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
`;

