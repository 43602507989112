import React from 'react';
import { TimeSlotStyle } from './timeSlotStyle';
import { TagComponent } from 'src/components';
import NoAppointment from '../../dashboard/appointment/NoAppointment';
import { EMPTY2 } from 'src/assets/images';

interface TimeSlotsProps {
  setTime: (value: Appointment.TimeSelectedProps[]) => void;
  times: Appointment.TimeSelectedProps[];
}
const TimeSlotSelector: React.FC<TimeSlotsProps> = ({ setTime, times }) => {
  const handleTagClick = (slotValue: string) => {
    const updatedTimeSlots = times.map(slot =>
      slot.value === slotValue
        ? { ...slot, selected: true }
        : { ...slot, selected: false }
    );
    setTime(updatedTimeSlots);
  };

  return (
    <TimeSlotStyle>
        {times?.length
          ? <div className="time-selected">
          {times.map((slot: Appointment.TimeSelectedProps) => (
            <div key={slot.value} onClick={() => {
              if (!slot.disabled) {
                handleTagClick(slot.value);
              }
            }}>
              <TagComponent content={slot} />
            </div>
          ))}
        </div>
          : <div className="no-data"><NoAppointment content="Không có khoản thời gian để chọn" image={EMPTY2} /></div>
        }
    </TimeSlotStyle>
  );
};

export default TimeSlotSelector;
