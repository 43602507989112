import { APPOINTMENT_STATUS, TIME_LINE } from 'src/constants';
import { ATTRIBUTES } from 'src/constants/attributes';

export const getTimeByKey = (key: string) => {
  const foundItem = TIME_LINE.find(item => item.key === key);
  return foundItem ? foundItem.value : 'Không tìm thấy thời gian';
};
export const getStatusByKey = (key: string) => {
  const foundItem = APPOINTMENT_STATUS.find(item => item.key === key);
  return foundItem ? foundItem.value : 'Không tìm thấy trạng thái';
};

export const getLabelByValue = (attributeValue: string) => {
  const attribute = ATTRIBUTES.find(attr => attr.value === attributeValue);
  return attribute ? attribute.label : null;
};
