import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

interface DropdownProps {
  trigger: React.ReactNode;
  items: Array<{ key: string; label: string | React.ReactNode; onClick: () => void }>;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
}

const Dropdown: React.FC<DropdownProps> = ({ trigger, items, placement = 'bottomLeft' }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const dropdownClass = `dropdown-menu ${placement}`;

  return (
    <DropdownStyle>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div onClick={toggleDropdown} className="dropdown-trigger">
          {trigger}
        </div>

        {open && (
          <div className={dropdownClass}>
              {items.map(item => (
                <div className="item" key={item.key} onClick={() => {
                  toggleDropdown()
                  item.onClick()
                }}>
                  {item.label}
                </div>
              ))}
          </div>
        )}
      </div>
    </DropdownStyle>
  );
};

export default Dropdown;

const DropdownStyle = styled.div`
  .custom-dropdown {
    position: relative;
    display: inline-block;
    .dropdown-menu{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .lable {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  

  .dropdown-trigger {
    cursor: pointer;
  }

  .dropdown-menu {
    position: absolute;
    min-width: 130px;
    width: max-content;
    padding: 10px;
    background-color: #242424;
    border: 1px solid #745812;
    color: #EAD38E;
    border-radius: 4px;
    font-size: 16px;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }


  .dropdown-menu .item {
    padding: 4px 0px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .dropdown-menu .item:hover {
    background-color: #5555553b;
  }

  /* Vị trí của menu */
  .dropdown-menu.bottomLeft {
    top: 100%;
    left: 0;
  }

  .dropdown-menu.bottomRight {
    top: 100%;
    right: 0;
  }

  .dropdown-menu.topLeft {
    bottom: 100%;
    left: 0;
  }

  .dropdown-menu.topRight {
    bottom: 100%;
    right: 0;
  }
`;
