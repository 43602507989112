import { createAsyncThunk } from '@reduxjs/toolkit';

import APPOINTMENT_API from './appointment.api';

export const createAppointmentAction = createAsyncThunk<any, Appointment.AppointmentRequestPayload>(
  'appointment',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await APPOINTMENT_API.createAppointmentAPI(data);
      if (result) callback();
      return result;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getAppointmentAction = createAsyncThunk<any, Appointment.AppointmentRequestPayload>(
  'get_appointment',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await APPOINTMENT_API.getAppointmentAPI(data);
      if (result) callback();
      return result;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
)

export const deleteAppointmentAction = createAsyncThunk<any, any>(
  'delete_appointment',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await APPOINTMENT_API.deleteAppointmentAPI(data);
      if (result) callback();
      return result;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
)
