import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { getDetailNewsAction } from 'src/stores/screens/dashboard/dashboard.action';
import { CardDashboardDetailStyle } from './detail';
import moment from 'moment-timezone';

const DetailNew: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { detailNew } = useAppSelector(state => state.dashboard);
  useEffect(() => {
    void onDetail();
  }, [id]);

  const onDetail = async () => {
    await dispatch(
      getDetailNewsAction({
        data: { id: id?.toString() },
        callback: {},
      }),
    );
  };

  return (
    <CardDashboardDetailStyle>
      <div className="title">{detailNew?.title}</div>
      <div style={{ display: 'flex', marginTop: '20px' }}>
        <div className="date">{moment(detailNew?.createdAt).format('DD/MM/YYYY')}</div>
        <div className="date" style={{ display: 'flex', marginLeft: '40px', marginBottom: '25px' }}>
          <div>Post by:</div>
          <span style={{ fontWeight: 700, marginLeft: '10px' }}>Admin</span>
        </div>
      </div>

      <div style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: detailNew?.content }} />
    </CardDashboardDetailStyle>
  );
};

export default DetailNew;
