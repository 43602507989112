import React, { useEffect, useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import _publicRoutes from './_public';
import _privateRoutes from './_private';
import { LOCAL_STORAGE_KEY } from 'src/constants';
import { useAppSelector } from 'src/stores';
import LocalStorage from 'src/utils/storage';

const RootRouter: React.FC = () => {
  const navigate = useNavigate();
  // const [token] = useLocalStorage(LOCAL_STORAGE_KEY.TOKEN, null);
  // const [userId] = useLocalStorage(LOCAL_STORAGE_KEY.USER_ID, null);
  const { meInfo, error } = useAppSelector(state => state.auth);
  const [routes, setRoutes] = useState<RouteObject[]>([..._publicRoutes]);

  useEffect(() => {
    const token = LocalStorage.get(LOCAL_STORAGE_KEY.TOKEN) as string;
    if (token) {
      // if (!meInfo) {
      //   void initialFunction();
      // } else {
      setRoutes([..._publicRoutes, ..._privateRoutes]);
      // }
    } else {
      setRoutes([..._publicRoutes]);
      navigate('/login');
    }
  }, [meInfo]);

  useEffect(() => {
    if (error) {
      LocalStorage.remove(LOCAL_STORAGE_KEY.TOKEN);
      navigate('/login');
    } else {
      setRoutes([..._publicRoutes, ..._privateRoutes]);
    }
  }, [error]);

  useEffect(() => {
    document.addEventListener('dragstart', function (e: any) {
      if (e?.target?.tagName === 'IMG') {
        e.preventDefault();
      }
    }, false);
  }, [])

  // if (isLoading) {
  //   return (
  //     <SpinStyle spinning={isLoading} wrapperClassName="root-spin" className="root-spin-component" tip="Loading...">
  //       {useRoutes(routes)}
  //     </SpinStyle>
  //   );
  // }

  return <>{useRoutes(routes)}</>;
};

export default RootRouter;

// const SpinStyle = styled(Spin)`
//   height: 100%;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
