/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Col, Form, Layout, Row, Divider, message } from 'antd';
import { Button, Image } from 'src/components';

import styled from 'styled-components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import { LARGE_LOGO } from 'src/assets/images';
import { InputField, PasswordField } from 'src/components/form';
import { resetPasswordAction, verificationMailAction } from 'src/stores/screens/auth/auth.action';
import { useAppDispatch } from 'src/stores';

const { Content } = Layout;

const ChangePasswordScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TIME_SEND_CODE = 60;

  const [seconds, setSeconds] = useState(TIME_SEND_CODE);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isActive, seconds]);

  useEffect(() => {
    onSendCode();
  }, []);

  const onSubmitSuccess = (): void => {
    setSeconds(TIME_SEND_CODE);
    setIsActive(true);
  };

  const onSendCode = () => {
    const dataNew = { email: location?.state?.mailState }
    void dispatch(verificationMailAction({ data: dataNew, callback: onSubmitSuccess }));
  };

  const validationSchema = yup.object().shape({
    code: yup.string().required('Mã xác thực là bắt buộc'),
    newPassword: yup.string().required('Mật khẩu mới là bắt buộc').min(8, 'Mật khẩu mới phải có ít nhất 8 ký tự'),
    email: yup.string().email('Email không hợp lệ').required('Email là bắt buộc')
    // confirmPassword: yup
    //   .string()
    //   .oneOf([yup.ref('password'), null], 'Passwords must match')
    //   .required('CONFIRM PASSWORD REQUIRED')
  });

  const initialValues = {
    code: '',
    newPassword: '',
    email: ''
    // confirmPassword: ''
  };

  const onVerifySuccess = (): void => {
    navigate('/login');
    void message.success('Cài lại mật khẩu thành công');
  };

  const handleVerify = (value): void => {
    void dispatch(resetPasswordAction({ data: value, callback: onVerifySuccess }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleVerify(value);
    }
  });

  const { setFieldValue } = formik;

  return (
    <ChangePasswordScreenStyle className="container-full">
      <Row>
        <Col
          xs={24}
          sm={13}
          className="box-logo"
        >
          <div className="logo">
            <Image src={LARGE_LOGO} />
          </div>
          <div className="title">
            <p className="brand-name">Phong Thủy Nhật Minh</p>
            <p className="description">
              Đặt lịch tư vấn, xem quẻ, luận giải về phong thủy
            </p>
          </div>
        </Col>
        <Col xs={24} sm={11} className="box-form">
          <Form
            name="login"
            className="login-form"
            onFinish={() => formik.handleSubmit()}
          >
            <p className="title">Nhập mật khẩu mới</p>
            <p className="sub-title">
              Vui lòng kiểm tra hộp thư để lấy mã xác minh
            </p>
            <div className="label-authen">
              <p className="label" style={{ marginLeft: '10px' }}>Mã xác minh</p>
              <>
                {seconds > 0
                  ? (
                  <p className="counter">Gửi lại mã ({seconds})s</p>
                    )
                  : (
                  <p className="resend-code counter" onClick={onSendCode}>
                    Gửi lại mã
                  </p>
                    )}
              </>
            </div>
            <InputField
              field={formik.getFieldProps('code')}
              setFieldValue={setFieldValue}
              className="form-control form-code"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập mã xác minh'
              }}
              error={formik.errors.code}
              touched={formik.touched.code}
            />
            <Divider />
            <InputField
              label="Email"
              field={formik.getFieldProps('email')}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập email'
              }}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
            <PasswordField
              label="Mật khẩu mới"
              field={formik.getFieldProps('newPassword')}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập mật khẩu mới',
              }}
              error={formik.errors.newPassword}
              touched={formik.touched.newPassword}
            />
            {/* <PasswordField
              label="Nhập lại mật khẩu mới"
              field={formik.getFieldProps('confirmPassword')}
              setFieldValue={setFieldValue}
              className="form-control form-password-confirmation"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập lại mật khẩu mới',
              }}
              error={formik.errors.confirmPassword}
              touched={formik.touched.confirmPassword}
            /> */}

            <Button className="btn-submit" htmlType="submit">
              Gửi yêu cầu
            </Button>
          </Form>
        </Col>
      </Row>
    </ChangePasswordScreenStyle>
  );
};

export default ChangePasswordScreen;

const ChangePasswordScreenStyle = styled(Content)`
  @import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-row {
    height: 100%;
    .ant-form-item {
      margin-bottom: 20px;
      .ant-form-item-row {
        flex-direction: column;
      }
      .ant-form-item-label > label {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        color: #ead38e;
      }
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #242424;
    .title {
      font-size: 32px;
      color: #ead38e;
      font-weight: bold;
    }
    .sub-title {
      font-size: 17px;
      color: #ffffff;
    }
    .label-authen {
      display: flex;
      justify-content: space-between;
      .label {
        color: #ead38e;
      }
      .counter {
        color: #1890ff;
      }
      .resend-code {
        cursor: pointer;
      }
    }
    .desc-label {
      color: #cdc29a;
    }
  }

  .login-form {
    width: 100%;
    input.ant-input {
      /* border: none; */
    }
  }

  .btn-submit {
    width: 100%;
  }
`;
