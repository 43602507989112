import React from 'react';
import { Select } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';
import { FormikTouched, FormikErrors } from 'formik';
import styled from 'styled-components';

type Props = {
  label?: string;
  field: string;
  optionsSelect: Array<{
    label: string | React.ReactElement;
    value: number | string;
    disabled?: boolean;
  }>;
  setFieldValue: (field: string, value: any) => void;
  selectProps?: SelectProps<any>;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  notFoundContent?: React.ReactNode;
  disabled?: boolean;
  defaultValue?: string | number;
  placeholder?: string;
  onBlur?: () => void;
} & Omit<FormItemProps, 'children'>;

const SelectCustom: React.FC<Props> = ({
  field,
  selectProps,
  optionsSelect,
  setFieldValue,
  notFoundContent,
  defaultValue,
  disabled,
  placeholder,
  onBlur
}) => {
  const onChange = (e: any): void => {
    setFieldValue(field, e);
  };

  return (
    <SelectCustomStyle>
      <Select
        {...selectProps}
        options={optionsSelect}
        onChange={onChange}
        notFoundContent={notFoundContent}
        disabled={disabled}
        defaultValue={defaultValue}
        popupClassName="custom-dropdown"
        placeholder={placeholder}
        onBlur={onBlur}
      />
    </SelectCustomStyle>
  );
};

export default SelectCustom;

const SelectCustomStyle = styled.div`
  min-width: 120px;
  @media screen and (max-width: 575px) {
    min-width: 70px;
  }
  .ant-select {
    width: 100%;
    background-color: #272727;
    color: #CDC29A;
    border-color: #342c24;
    .ant-select-selector {
      background-color: #272727;
      color: #CDC29A;
      border-color: #342c24;
    }

    .ant-select-arrow {
      color: #CDC29A;
    }
  }
  
`;
