import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const DASHBOARD_API = {
  getDashboardAPI: async () => {
    const response = await new AxiosClient().get('/appointment/dashboard');
    const data = get(response, 'data', null);
    return data;
  },
  getPinedAPI: async () => {
    const response = await new AxiosClient().get('/consultation/pined');
    const data = get(response, 'data', null);
    return data;
  },
  getNewsAPI: async () => {
    const response = await new AxiosClient().get('/news');
    const data = get(response, 'data', null);
    return data;
  },
  getDetailNewsAPI: async (params: { id: string }) => {
    const response = await new AxiosClient().get(`/news/${params?.id ?? ''}`);
    const data = get(response, 'data', null);
    return data;
  },
  deletePinedAPI: async (params: { id: string, pinned: boolean }) => {
    const response = await new AxiosClient().put(
      `/consultation/${params?.id ?? ''}`, { pinned: params?.pinned }
    )
    const data = get(response, 'data', null);
    return data;
  },
};

export default DASHBOARD_API;
