import React from 'react';
import _ from 'lodash';
import { Avatar, Collapse, Typography } from 'antd';
import { ChatSectionStyle } from './ChatSectionStyle';
import { formatVietnameseDate, getTimeByKey } from 'src/utils';
import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'src/stores';
import { DEFAULT_AVATAR } from 'src/assets/images';
import { formatVietnameseMonth } from 'src/utils/dateTime';
import moment from 'moment-timezone';

const { Panel } = Collapse;
const { Text } = Typography;

interface ChatSectionProps {
  message: Consultation.Appointment;
}

const ChatSection: React.FC<ChatSectionProps> = ({ message }) => {
  const { user } = useAppSelector(state => state.user);
  const navigate = useNavigate();
  const { id, detailId } = useParams();
  const consultationId = Number(id);
  const appointmentId = Number(detailId);

  const handleChatSectionClick = (e: React.MouseEvent<HTMLDivElement>, item: any) => {
    e.stopPropagation();
    navigate(`/consultation/${consultationId}/${item.id}`);
  }

  const getLunarYearName = (year: number): string => {
    const thienCan: string[] = ['Giáp', 'Ất', 'Bính', 'Đinh', 'Mậu', 'Kỷ', 'Canh', 'Tân', 'Nhâm', 'Quý'];
    const diaChi: string[] = ['Tý', 'Sửu', 'Dần', 'Mão', 'Thìn', 'Tỵ', 'Ngọ', 'Mùi', 'Thân', 'Dậu', 'Tuất', 'Hợi'];

    const canIndex: number = (year + 6) % 10;
    const chiIndex: number = (year + 8) % 12;
    const can: string = thienCan[canIndex];
    const chi: string = diaChi[chiIndex];
    return `${can} ${chi}`;
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const getDisable = (date) => {
    const repeatYear = parseInt(date.split('-')[0]);
    const repeatMonth = parseInt(date.split('-')[1]);

    return (repeatYear > currentYear || (repeatYear === currentYear && repeatMonth > currentMonth));
  }

  const renderChat = () => {
    return (
      <div className={`message-item ${consultationId === message?.id ? 'active' : ''}`}>
      {/* <div className={`message-item ${idSelected?.id === message?.id ? 'active' : ''}`}> */}
        <Avatar src={`${user?.avatar ? `data:image/png;base64, ${user?.avatar}` : DEFAULT_AVATAR}`} />
        <div>
          <div>
            <Text className="title">{message?.isRepeated === 0 ? formatVietnameseDate(message.requestDate) : formatVietnameseMonth(message.requestDate) + ' ' + `(${getLunarYearName(parseInt(message?.requestDate?.split('-')[0], 10))})`}</Text>
          </div>
          {message?.isRepeated === 0 &&
            <div>
              <Text className="timestamp" type="secondary">
                {getTimeByKey(message.requestTime)}
              </Text>
            </div>
          }
        </div>
      </div>
    );
  };

  return (
    <ChatSectionStyle>
      {message?.isRepeated
        ? <StyledCollapse
          accordion
          expandIconPosition="right"
          expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} style={{ color: 'white' }} />}
        >
          <Panel header={renderChat()} key={message.id}>
            {_.map(message.appointments, (item: Consultation.Appointment) => (
              <div key={item.id} className={`collapse-item ${item.repeatTime ? getDisable(item.repeatTime.split('-').slice(0, 2).join('-')) ? 'disable-time' : '' : ''} ${appointmentId === item.id ? 'active' : ''}`} onClick={(e) => handleChatSectionClick(e, item)}>{moment(item.repeatTime).format('MM-YYYY')}</div>
            ))}
          </Panel>
        </StyledCollapse>
        : renderChat()
      }

    </ChatSectionStyle>
  );
};

export default ChatSection;

const StyledCollapse = styled(Collapse)`
  background-color: unset;
  color: black;
  border-radius: 4px;
  border: unset;
  .ant-collapse-item {
    .ant-collapse-header {
      /* background-color: #EFD485; */
      color: black !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
    }
  }

  .ant-collapse-arrow {
    position: absolute;
    right: 16px; /* Đặt icon ở ngoài cùng bên phải */
    color: black;
  }

  .ant-collapse-content {
    background-color: #22201e !important;
    border: unset;
    .ant-collapse-content-box {
      padding: 12px 0px;
    }
    .ant-collapse-content-box {
      .active {
        background: #745812;
        color: #cfbf83;
        border-radius: 4px;
      }
      .collapse-item:hover{
        background: #745812;
        border-radius: 4px;
      }
    }
  }

  .ant-collapse-content p {
    color: #cdc29a;
  }

  .ant-collapse-item {
    border: unset;
  }
  .collapse-item {
    color: #cdc29a;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    text-align: left;
    cursor: pointer;
  }
  .disable-time {
    cursor: not-allowed;
    pointer-events: none;
    color: #555143;
  }
`;
