/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
import React from 'react';
import { Col, Form, Layout, Row, message } from 'antd';
import { Button, Image } from 'src/components';

import styled from 'styled-components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';

import { LARGE_LOGO } from 'src/assets/images';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { registerAction } from 'src/stores/screens/auth/auth.action';
import {
  InputField,
  CheckboxField,
  PasswordField,
  RadioGroupField,
  SelectField,
} from 'src/components/form';

const { Content } = Layout;

const RegisterScreen: React.FC = () => {
  const { isLoading } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const PHONE_NUMBER = /^[0-9]{10,11}$|^$/;
  const validationSchema = yup.object().shape({
    email: yup.string().email('Email không hợp lệ').required('Email là bắt buộc'),
    password: yup.string().required('Mật khẩu là bắt buộc').min(8, 'Mật khẩu phải có ít nhất 8 ký tự'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Mật khẩu phải khớp nhau')
      .required('Nhập lại Mật khẩu là bắt buộc'),
    username: yup.string().required('Họ tên là bắt buộc'),
    phoneNumber: yup.string().required('Số điện thoại là bắt buộc').matches(PHONE_NUMBER, 'Số điện thoại không đúng định dạng'),
    agree: yup.boolean().required('agree is required'),
    gender: yup.number().required('Gender is required'),
    dateOfBirthLunar: yup.string().required('Ngày sinh âm lịch là bắt buộc'),
    monthOfBirthLunar: yup.string().required('Ngày sinh âm lịch là bắt buộc'),
    yearOfBirthLunar: yup.string().required('Ngày sinh âm lịch là bắt buộc'),
  });

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    username: '',
    phoneNumber: '',
    agree: false,
    gender: 1,
    dateOfBirthLunar: '',
    monthOfBirthLunar: '',
    yearOfBirthLunar: '',
    dateOfBirth: '',
  };

  const onRegisterSuccess = (): void => {
    message.success('Đăng ký thành công!');
    navigate('/login');
    // navigate('/verify_email');
  };

  const handleRegister = (value: Auth.RegisterRequestData): void => {
    console.log(value);
    const yearOfBirthLunar = value.yearOfBirthLunar ?? '';
    const monthOfBirthLunar = value.monthOfBirthLunar ?? '';
    const dateOfBirthLunar = value.dateOfBirthLunar ?? '';
    let dataNew = value;
    dataNew = {
      ...dataNew,
      dateOfBirth: '1990-01-01',
      dateOfBirthLunar: `${yearOfBirthLunar}-${monthOfBirthLunar}-${dateOfBirthLunar}`,
    };
    void dispatch(registerAction({ data: dataNew, callback: onRegisterSuccess }));
    // onRegisterSuccess();
    // void dispatch(registerAction({ data: value, callback: onRegisterSuccess }));
  };

  const getLunarYearName = (year: number): string => {
    const thienCan: string[] = ['Giáp', 'Ất', 'Bính', 'Đinh', 'Mậu', 'Kỷ', 'Canh', 'Tân', 'Nhâm', 'Quý'];
    const diaChi: string[] = ['Tý', 'Sửu', 'Dần', 'Mão', 'Thìn', 'Tỵ', 'Ngọ', 'Mùi', 'Thân', 'Dậu', 'Tuất', 'Hợi'];

    const canIndex: number = (year + 6) % 10;
    const chiIndex: number = (year + 8) % 12;
    const can: string = thienCan[canIndex];
    const chi: string = diaChi[chiIndex];
    return `${can} ${chi}`;
  };

  const currentYear: number = new Date().getFullYear();
  const lunarYears: Array<{ value: number; label: string }> = Array.from(
    { length: 100 },
    (_, i) => currentYear - i,
  ).map((year: number) => ({
    value: year,
    label: `${getLunarYearName(year)} (${year})`,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value: Auth.RegisterRequestData) => {
      handleRegister(value);
    },
  });

  const { setFieldValue } = formik;

  return (
    <RegisterScreenStyle className="container-full">
      <Row>
        <Col xs={24} sm={24} md={24} lg={13} className="box-logo">
          <div className="logo">
            <Image src={LARGE_LOGO} />
          </div>
          <div className="title">
            <p className="brand-name">Phong Thủy Nhật Minh</p>
            <p className="description">Đặt lịch tư vấn, xem quẻ, luận giải về phong thủy</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={11} className="box-form">
          <Form name="login" className="login-form" onFinish={() => formik.handleSubmit()}>
            <p className="title">Đăng ký</p>
            <p className="sub-title">
              Bạn đã có tài khoản?
              <Link to={'/login'}> Đăng nhập ngay</Link>
            </p>
            <InputField
              label="Họ và tên"
              field={formik.getFieldProps('username')}
              setFieldValue={setFieldValue}
              className="form-control form-username"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập họ và tên',
              }}
              error={formik.errors.username}
              touched={formik.touched.username}
              required
            />
            <InputField
              label="Email"
              field={formik.getFieldProps('email')}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập Email',
              }}
              error={formik.errors.email}
              touched={formik.touched.email}
              required
            />

            <InputField
              label="Số điện thoại"
              field={formik.getFieldProps('phoneNumber')}
              setFieldValue={setFieldValue}
              className="form-control form-phoneNumber"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập số điện thoại',
              }}
              error={formik.errors.phoneNumber}
              touched={formik.touched.phoneNumber}
              required
            />
            <div style={{ display: 'flex' }}>
              <div className='text-label'>Ngày sinh âm lịch</div>
              <span style={{ color: '#ff4d4f' }}>*</span>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <SelectField
                field={formik.getFieldProps('dateOfBirthLunar')}
                className="form-control form-dateOfBirthLunar"
                setFieldValue={setFieldValue}
                optionsSelect={[
                  { label: '1', value: '01' },
                  { label: '2', value: '02' },
                  { label: '3', value: '03' },
                  { label: '4', value: '04' },
                  { label: '5', value: '05' },
                  { label: '6', value: '06' },
                  { label: '7', value: '07' },
                  { label: '8', value: '08' },
                  { label: '9', value: '09' },
                  { label: '10', value: '10' },
                  { label: '11', value: '11' },
                  { label: '12', value: '12' },
                  { label: '13', value: '13' },
                  { label: '14', value: '14' },
                  { label: '15', value: '15' },
                  { label: '16', value: '16' },
                  { label: '17', value: '17' },
                  { label: '18', value: '18' },
                  { label: '19', value: '19' },
                  { label: '20', value: '20' },
                  { label: '21', value: '21' },
                  { label: '22', value: '22' },
                  { label: '23', value: '23' },
                  { label: '24', value: '24' },
                  { label: '25', value: '25' },
                  { label: '26', value: '26' },
                  { label: '27', value: '27' },
                  { label: '28', value: '28' },
                  { label: '29', value: '29' },
                  { label: '30', value: '30' },
                ]}
                placeholder={'Ngày'}
                error={formik.errors.dateOfBirthLunar ?? formik.errors.monthOfBirthLunar ?? formik.errors.yearOfBirthLunar}
                touched={formik.touched.dateOfBirthLunar ?? formik.touched.monthOfBirthLunar ?? formik.touched.yearOfBirthLunar}
                required
              />
              <div style={{ minWidth: '100px', marginBottom: '10px' }}>
                <SelectField
                  field={formik.getFieldProps('monthOfBirthLunar')}
                  optionsSelect={[
                    { label: '1', value: '01' },
                    { label: '2', value: '02' },
                    { label: '3', value: '03' },
                    { label: '4', value: '04' },
                    { label: '5', value: '05' },
                    { label: '6', value: '06' },
                    { label: '7', value: '07' },
                    { label: '8', value: '08' },
                    { label: '9', value: '09' },
                    { label: '10', value: '10' },
                    { label: '11', value: '11' },
                    { label: '12', value: '12' },
                  ]}
                  className="form-control form-monthOfBirthLunar"
                  setFieldValue={setFieldValue}
                  placeholder={'Tháng'}
                />
              </div>
              <div style={{ minWidth: '140px' }}>
                <SelectField
                  field={formik.getFieldProps('yearOfBirthLunar')}
                  optionsSelect={lunarYears}
                  className="form-control form-yearOfBirthLunar"
                  setFieldValue={setFieldValue}
                  placeholder={'Năm'}
                />
              </div>
            </div>
            <RadioGroupField
              label="Giới tính"
              field={formik.getFieldProps('gender')}
              optionsSelect={[
                { label: 'Nam', value: 1 },
                { label: 'Nữ', value: 0 },
              ]}
              className="form-control form-luna-birthday"
              error={formik.errors.gender}
              touched={formik.touched.gender}
              onChange={e => formik.setFieldValue('gender', e.target.value)}
              disabled={false}
              required
            />

            <PasswordField
              label="Mật khẩu"
              field={formik.getFieldProps('password')}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập mật khẩu',
              }}
              error={formik.errors.password}
              touched={formik.touched.password}
              required
            />

            <PasswordField
              label="Nhập lại mật khẩu"
              field={formik.getFieldProps('confirmPassword')}
              setFieldValue={setFieldValue}
              className="form-control form-password-confirmation"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập lại mật khẩu',
              }}
              error={formik.errors.confirmPassword}
              touched={formik.touched.confirmPassword}
              required
            />

            <div className="terms-conditions">
              <CheckboxField
                id="agree"
                field={formik.getFieldProps('agree')}
                setFieldValue={setFieldValue}
                className="form-control form-agree"
                // error={formik.errors.agree}
                // touched={formik.touched.agree}
              />
              <label htmlFor="agree">Tôi đồng ý với chính sách & điều khoản</label>
            </div>

            <Button className="btn-submit" htmlType="submit" disabled={!formik.values.agree} loading={isLoading}>
              Đăng ký
            </Button>
          </Form>
        </Col>
      </Row>
    </RegisterScreenStyle>
  );
};

export default RegisterScreen;

const RegisterScreenStyle = styled(Content)`
  @import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: #342c24  !important;
  }
  .ant-row {
    height: 100%;
    .ant-form-item {
      margin-bottom: 20px;
      .ant-form-item-row {
        flex-direction: column;
      }
      .ant-form-item-label > label {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        color: #ead38e;
      }
    }
    .terms-conditions {
      display: flex;
      flex-direction: row;
      justify-content: start;
      gap: 10px;
      margin-bottom: 20px;
      color: #ffffff;
      label,
      .form-agree {
        margin: auto 0;
      }
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #242424;
    .title {
      font-size: 32px;
      color: #ead38e;
      font-weight: bold;
    }
    .sub-title {
      font-size: 17px;
      color: #ffffff;
      a {
        color: red !important;
      }
    }
  }

  .login-form {
    width: 100%;
    .text-label {
      color: rgb(234, 211, 142);
      margin-left: 10px;
    }
    @media screen and (max-width: 575px) {
      margin-left: 0px;
    }
    input.ant-input {
      /* border: none; */
    }
  }

  .btn-submit {
    width: 100%;
  }
`;
