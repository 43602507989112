import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { useOutlet } from 'react-router-dom';
import { DARK_BACKGROUND } from 'src/assets/images';
import Media from 'src/utils/media';

const { Content } = Layout;

const PublicLayout: React.FC = () => {
  const outlet = useOutlet();
  return (
    <PublicLayoutStyle>
      <Layout className="layout-body">
        <Content className="body-content">{outlet}</Content>
      </Layout>
    </PublicLayoutStyle>
  );
};

export default PublicLayout;

const PublicLayoutStyle = styled(Layout)`
  height: 100%;
  width: 100%;
  min-height: 100vh;

  .layout-sider {
    background-color: transparent;
    box-shadow: 1px 1px 1px lightblue;

    .logo {
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 1px 1px 1px lightblue;
    }

    .layout-menu {
      height: calc(100% - 48px);
      background-color: transparent;

      .ant-menu-item {
        margin-top: initial;
        margin-bottom: 10px;
      }

      .ant-menu-item-selected {
        background-color: #b8d8f1;
      }
    }
  }

  .layout-body {
    .body-header {
      padding: 0;
      background-color: transparent;
      box-shadow: 1px 1px 1px lightblue;
      text-align: right;
      padding: 0 30px;
    }
    .body-content {
      width: 100%;
      margin: 0 auto;
      ${Media.lessThan(Media.SIZE.XL)} {
        max-height: 100%;
      }
      .ant-layout-content {
        .box-logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-image: url(${DARK_BACKGROUND});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-attachment: fixed;
          color: #ead38e;
          text-align: center;
          .brand-name {
            font-size: 40px;
            line-height: 0;
            ${Media.lessThan(Media.SIZE.MD)} {
              font-size: 20px;
            }
          }
          .description {
            font-size: 28px;
            ${Media.lessThan(Media.SIZE.MD)} {
              font-size: 16px;
            }
          }
          .ant-image-img {
            height: 30%;
            width: 30%;
          }
        }
        .box-form {
          padding: 40px 10%;
        }
      }
    }
    ${Media.lessThan(Media.SIZE.MD)} {
      .body-content {
        padding: 20px 0px;
      }
    }
    .body-footer {
      text-align: center;
    }
  }
`;
