import React from 'react';
import { Col, Form, Layout, Row } from 'antd';
import { Button, Image } from 'src/components';

import styled from 'styled-components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';

import { LARGE_LOGO } from 'src/assets/images';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { InputField, PasswordField } from 'src/components/form';
import { loginAction } from 'src/stores/screens/auth/auth.action';

const { Content } = Layout;

const LoginScreen: React.FC = () => {
  const { isLoading } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    email: yup.string().email('Email không hợp lệ').required('Email là bắt buộc'),
    password: yup.string().required('Mật khẩu là bắt buộc').min(8, 'Mật khẩu phải có ít nhất 8 ký tự')
  });

  const initialValues = {
    email: '',
    password: ''
  };

  const onLoginSuccess = (): void => {
    navigate('/dashboard');
  };

  const handleLogin = (value): void => {
    void dispatch(loginAction({ data: value, callback: onLoginSuccess }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleLogin(value);
    }
  });

  const { setFieldValue } = formik;

  return (
    <LoginScreenStyle className="container-full">
      <Row>
        <Col
          xs={24}
          sm={13}
          className="box-logo"
        >
          <div className="logo">
            <Image src={LARGE_LOGO} />
          </div>
          <div className="title">
            <p className="brand-name">Phong Thủy Nhật Minh</p>
            <p className="description">
              Đặt lịch tư vấn, xem quẻ, luận giải về phong thủy
            </p>
          </div>
        </Col>
        <Col xs={24} sm={11} className="box-form">
          <Form
            name="login"
            className="login-form"
            onFinish={() => formik.handleSubmit()}
          >
            <p className="title">Đăng nhập</p>
            <p className="sub-title">
              Chưa có tài khoản?
              <Link to={'/register'}> Đăng ký ngay</Link>
            </p>
            <InputField
              label="Email"
              field={formik.getFieldProps('email')}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập Email'
              }}
              error={formik.errors.email}
              touched={formik.touched.email}
            />

            <PasswordField
              label="Mật khẩu"
              field={formik.getFieldProps('password')}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập mật khẩu',
              }}
              error={formik.errors.password}
              touched={formik.touched.password}
            />

            <Button className="btn-submit" htmlType="submit" loading={isLoading}>
              Đăng nhập
            </Button>
          </Form>
          <Link to={'/forgot_password'} className="forgot-password">
            Quên mật khẩu
          </Link>
        </Col>
      </Row>
    </LoginScreenStyle>
  );
};

export default LoginScreen;

const LoginScreenStyle = styled(Content)`
  @import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-row {
    height: 100%;
    .ant-form-item-row {
      flex-direction: column;
    }
    .ant-form-item-label > label {
      display: flex;
      color: #ead38e;
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #242424;
    .title {
      font-size: 32px;
      color: #ead38e;
      font-weight: bold;
    }
    .sub-title {
      font-size: 17px;
      color: #ffffff;
      a {
        color: red !important;
      }
    }
    .forgot-password {
      margin-top: 10px;
      display: flex;
      justify-content: end;
    }
  }

  .login-form {
    width: 100%;
    input.ant-input {
      /* border: none; */
    }
  }

  .btn-submit {
    width: 100%;
  }
`;
