import styled from 'styled-components';

export const CardDashboardDetailStyle = styled.div`
  border: 1px solid rgb(116, 88, 18);
  width: 100%;
  max-width: 1458px;
  height: calc(100vh - 160px);
  padding: 0 16px;
  overflow: auto;
  margin: 0 auto;
  border-radius: 8px;
  &::-webkit-scrollbar {
    width: 3px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #745811;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #715e2d;
  }
  @media screen and (max-width: 575px) {
    padding: 0 16px;
  }
  .title {
    color: #ead38e;
    font-size: 40px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    padding: 0 120px;
    line-height: 55px;
    @media screen and (max-width: 575px) {
      padding: 0;
    }
  }
  .date {
    color: #cdc29a;
    font-size: 16px;
  }
`;
