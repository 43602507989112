import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const COMMON_API = {
  busyTimeAPI: async (params: { startDate: string, endDate: string }) => {
    const response = await new AxiosClient().get(
      '/common/busy-time', { params },
    );
    const data = get(response, 'data', null);
    return data;
  },
};

export default COMMON_API;
