import { createAsyncThunk } from '@reduxjs/toolkit';

import DASHBOARD_API from './dashboard.api';


export const getDashboardAction = createAsyncThunk<any, Appointment.DashboardRequestPayload>(
  'get_dashboard',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await DASHBOARD_API.getDashboardAPI();
      if (result) callback();
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
)

export const getPinedAction = createAsyncThunk<any, any>(
  'get_pined',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await DASHBOARD_API.getPinedAPI();
      if (result) callback();
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
)

export const getNewsAction = createAsyncThunk<any, any>(
  'get_news',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await DASHBOARD_API.getNewsAPI();
      if (result) callback();
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
)

export const getDetailNewsAction = createAsyncThunk<any, any>(
  'get_detail_news',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await DASHBOARD_API.getDetailNewsAPI(data);
      return result;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
)

export const deletePinedAction = createAsyncThunk<any, any>(
  'delete_pined',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await DASHBOARD_API.deletePinedAPI(data);
      if (result) callback();
      return result;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
)

