import React from 'react';
import DashboardStyle from './DashboardStyle';
import Appointment from './appointment/Appointment';
// import NoAppointment from './appointment/NoAppointMent';

const DashboardScreen: React.FC = () => {
  return (
    <DashboardStyle className="container">
      {/* <NoAppointment /> */}
      {/* <hr /> */}
      <Appointment/>
    </DashboardStyle>
  );
};

export default DashboardScreen;
