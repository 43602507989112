import React from 'react';
import { EMPTY } from 'src/assets/images';
import { Image } from 'src/components';
import styled from 'styled-components';

const NoDataStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 40px;
  font-size: 16px;
`

interface NoAppointmentProps {
  content?: string;
  image?: string;
}

const NoAppointment: React.FC<NoAppointmentProps> = ({ content, image, ...props }) => {
  return (
    <NoDataStyle className="content-center">
      <Image src={image ?? EMPTY} />
      <div className="content">{content ?? <div>{content}</div>}</div>
    </NoDataStyle>
  );
};

export default NoAppointment;
