import styled from 'styled-components';

export const TimeSlotStyle = styled.div`
  padding-top: 20px;
  .time-selected {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .ant-tag {
      margin: 0px;
    }
  }
`;
