import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const USER_API = {
  getUserAPI: async (params: any) => {
    const response = await new AxiosClient().get('/user/profile', params);
    const data = get(response, 'data', null);
    return data;
  },

  updateUserAPI: async (params: any) => {
    const response = await new AxiosClient().patch('/user/profile', params);
    const data = get(response, 'data', null);
    return data;
  },

  changePasswordAPI: async (params: any) => {
    const response = await new AxiosClient().patch('/user/change-password', params);
    const data = get(response, 'data', null);
    return data;
  },
};

export default USER_API;
