import styled from 'styled-components';

export const TimeLineStyleStyle = styled.div`
  width: 100%;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ead38e;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .wrapper-content {
    width: 100%;
    padding: 12px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 208, 0, 0.22) 100%);
    border-left: 2px solid #af9026;
    margin: 12px 0;

    .date-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.41px;
        text-align: left;
        color: #ead38e;
      }
      .label-day {
        width: fit-content;
        padding: 0 4px;
        border-radius: 4px;
        background: #ead38e;
        color: #342c24;
        font-size: 12px;
        padding: 2px 8px;
      }
    }
    .status {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-align: left;
      color: #ffffff;
      margin-top: 6px;
      .pending {
        background-color: #2761f5;
        padding: 2px 8px;
        border-radius: 4px;
      }
      .approve {
        background-color: #38a107;
        padding: 2px 8px;
        border-radius: 4px;
      }
      .reject {
        background-color: #db1f1f;
        padding: 2px 8px;
        border-radius: 4px;
      }
    }
  }
`;

export const WrapperNoteStyle = styled.div`
  /* display: flex;
  gap: 20; */
  .block-img-slice {
    margin-top: 20px;
    .ant-image {
      width: 100% !important;
      border-right: 1px solid rgb(116, 88, 18);
      border-left: 1px solid rgb(116, 88, 18);
      border-top: 1px solid rgb(116, 88, 18);
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }
  .block-new {
    line-height: 20px;
    height: inherit;
    padding: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: 1px solid #745812;
    border-left: 1px solid #745812;
    border-bottom: 1px solid #745812;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 208, 0, 0.22) 100%);
  }
  .car-desciption {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #ead38e;
    font-size: 12px;
    margin-top: 3px;
  }
  .carousel-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #fff;
    font-size: 12px;
  }
  .car-date {
    color: #ead38e;
    font-size: 12px;
    margin-top: 5px;
  }
`;
