/* eslint-disable no-console */
import React from 'react';
import { Avatar, Upload, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import type { RcFile } from 'antd/es/upload';
import { useAppDispatch } from 'src/stores';
import { uploadFileAction } from 'src/stores/screens/auth/auth.action';

interface AvatarUploaderProps {
  image: string;
  onChangeFile: (value: any) => void;
}
const AvatarUploader: React.FC<AvatarUploaderProps> = ({ image, onChangeFile }) => {
  const dispatch = useAppDispatch();
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPng) {
      void message.error('Bạn chỉ có thể tải lên tệp jpeg/jpg/png!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      void message.error('Hình ảnh phải nhỏ hơn 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    const result: any = await dispatch(uploadFileAction({ data: formData, callback: () => { } }));
    if (result?.error) {
      void message.error('Cập nhật ảnh đại diện thất bại')
    } else {
      void message.success('Cập nhật ảnh đại diện thành công')
    }
    onChangeFile(result?.payload?.avatar)
  };


  return (
    <div>
      <Upload
        name="avatar"
        showUploadList={false}
        accept=".jpeg,.jpg,.png"
        beforeUpload={(file) => {
          if (beforeUpload(file)) {
            void handleUploadFile(file);
          }
          // return false;
        }}
        // onChange={handleChange}
      >
        <Avatar
          size={100}
          icon={<UserOutlined />}
          src={image}
          style={{ cursor: 'pointer' }}
        />
      </Upload>
    </div>
  );
};

export default AvatarUploader;
