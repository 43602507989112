import styled from 'styled-components';

const DashboardStyle = styled.div`
  .content-center {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #ffffff;
  }
  .btn-submit {
    margin-top: 24px;
  }
`;

export default DashboardStyle;
