import { createAsyncThunk } from '@reduxjs/toolkit';

import { setUserData } from 'src/utils';
import AUTH_API from './auth.api';

export const loginAction = createAsyncThunk<any, Auth.LoginRequestPayload>(
  'auth',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const user: Auth.MeInfo = await AUTH_API.loginAPI(data);

      if (user?.accessToken) {
        setUserData(user);
        callback();
      }
      return user;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const registerAction = createAsyncThunk<any, Auth.LoginRequestPayload>(
  'register',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await AUTH_API.registerAPI(data);
      if (result) {
        callback();
      }
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const getMeAction = createAsyncThunk<any, { userId: string }>(
  'me',
  async ({ userId }, { dispatch, rejectWithValue }) => {
    try {
      const res = await AUTH_API.getMeAPI(userId);

      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const uploadFileAction = createAsyncThunk<any, any>(
  'uploadFile',
  async ({ data, callback = () => { } }, { dispatch, rejectWithValue }) => {
    try {
      const result = await AUTH_API.uploadFileAPI(data);
      return result;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const verificationMailAction = createAsyncThunk<any, any>(
  'send-verification-code',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await AUTH_API.verificationCodeAPI(data);
      if (result) {
        callback();
      }
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const resetPasswordAction = createAsyncThunk<any, any>(
  'reset-password',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await AUTH_API.resetPasswordAPI(data);
      if (result) {
        callback();
      }
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
