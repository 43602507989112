import React from 'react';
import type { RouteObject } from 'react-router-dom';

import { PublicLayout } from 'src/layouts';
import { NotFound } from 'src/screens/NotFound';
import { ChangePasswordScreen, ForgotPasswordScreen, LoginScreen, RegisterScreen, VerifyEmailScreen } from 'src/screens/publicScreens';

// const HomeScreen = React.lazy(
//   async () => await import('src/screens/publicScreens').then(module => ({ default: module.HomeScreen })),
// );
// const AboutScreen = React.lazy(
//   async () => await import('src/screens/publicScreens').then(module => ({ default: module.AboutScreen })),
// );
// const LoginScreen = React.lazy(
//   async () => await import('src/screens/publicScreens').then(module => ({ default: module.LoginScreen })),
// );

const _publicRoutes: RouteObject[] = [

  {
    element: <PublicLayout />,
    children: [
      { path: '/login', element: <LoginScreen /> },
      { path: '/register', element: <RegisterScreen /> },
      { path: '/verify_email', element: <VerifyEmailScreen /> },
      { path: '/forgot_password', element: <ForgotPasswordScreen /> },
      { path: '/change_password', element: <ChangePasswordScreen /> },
      { element: <NotFound />, path: '*' },
    ],
  },
  // {
  //   path: '/',
  //   element: <PrivateLayout />,
  //   // element: <PublicLayout />,
  //   children: [{ element: <HomeScreen /> }, { path: 'about', element: <AboutScreen /> }],
  // },
  // {
  //   element: <LoginScreen />,
  //   path: 'login',
  // },
  // {
  //   element: <RegisterScreen />,
  //   path: 'register',
  // },

  // { element: <NotFoundScreen />, path: '*' },
];

export default _publicRoutes;
