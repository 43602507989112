import React from 'react';
import { ModalComponentStyle } from './modalStyle';
import Button from '../button';

interface timeComponentProps {
  children?: any;
  width?: number;
  title?: string;
  open: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  className?: string;
  okText?: string;
  cancelText?: string;
}

const ModalComponent: React.FC<timeComponentProps> = ({
  children,
  width,
  title,
  open,
  onOk,
  onCancel,
  className,
  okText,
  cancelText,
  ...props
}) => {
  return (
    <ModalComponentStyle
      centered
      width={width}
      title={title}
      open={open}
      className={className}
      onOk={onOk}
      okText={okText}
      cancelText={cancelText}
      onCancel={onCancel}
      footer={null}
      {...props}
    >
      {children}
      <div className="button-footer">
        {cancelText && (
          <Button buttonType="cancel" onClick={onCancel} width={100}>
            {cancelText}
          </Button>
        )}
        {okText && (
          <Button onClick={onOk} width={100}>
            {okText}
          </Button>
        )}
      </div>
    </ModalComponentStyle>
  );
};

export default ModalComponent;
