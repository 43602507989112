import React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

// Styled Collapse container
const StyledCollapse = styled(Collapse)`
  background-color: unset;
  color: black;
  border-radius: 4px;
  border: unset;

  .ant-collapse-header {
    background-color: #EFD485;
    color: black !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-collapse-arrow {
    position: absolute;
    right: 16px; /* Đặt icon ở ngoài cùng bên phải */
    color: black;
  }

  .ant-collapse-content {
    background-color: #22201e !important;
    border: unset;
  }

  .ant-collapse-content p {
    color: #cdc29a !important;
  }

  .ant-collapse-item {
    border: unset;
  }
  .collapse-item {
    color: #cdc29a;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    text-align: left;
    cursor: pointer;   
  }
`;

const CustomCollapse: React.FC = () => {
  return (
    <StyledCollapse
      accordion
      expandIconPosition="right" // Đặt icon nằm bên phải
      expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />} // Tùy chỉnh icon
    >
      <Panel header="Panel 1" key="1">
        <div className="collapse-item">23-09-2024</div>
        <div className="collapse-item">23-09-2024</div>
        <div className="collapse-item">23-09-2024</div>
        <div className="collapse-item">23-09-2024</div>
        <div className="collapse-item">23-09-2024</div>
        <div className="collapse-item">23-09-2024</div>
        <div className="collapse-item">23-09-2024</div>
      </Panel>
    </StyledCollapse>
  );
};

export default CustomCollapse;
