/* eslint-disable no-console */
import React from 'react';
import { Col, Form, message, Row } from 'antd';
import * as yup from 'yup';
import { useFormik } from 'formik';
// import { useNavigate } from 'react-router-dom';
// import { useAppDispatch } from 'src/stores';
import { PasswordField } from 'src/components/form';
import { CardStyle } from 'src/utils/styled';
import 'react-calendar/dist/Calendar.css';
import { Button, Image } from 'src/components';
import { ChangePasswordStyle } from './ChangePasswordStyle';
import { ICON_BACK } from 'src/assets/images';
import { changePasswordAction } from 'src/stores/screens/user/user.action';
import { useAppDispatch } from 'src/stores';

interface ChangePasswordProps {
  onBack: () => any;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ onBack }) => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required('Mật khẩu là bắt buộc').min(8, 'Mật khẩu phải có ít nhất 8 ký tự').max(16, 'Mật khẩu phải ít hơn 16 ký tự'),
    newPassword: yup.string().required('Mật khẩu mới là bắt buộc').min(8, 'Mật khẩu phải có ít nhất 8 ký tự').max(16, 'Mật khẩu phải ít hơn 16 ký tự'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Mật khẩu xác nhận không trùng khớp với mật khẩu mới')
      .required('Xác nhận mật khẩu là bắt buộc')
      .min(8),
  });

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const changePasswordSuccess = () => {
    void message.success('Thay đổi mật khẩu thành công')
  }

  const handleChangePassword = async (value: any) => {
    const result: any = await dispatch(changePasswordAction({ data: value, callback: changePasswordSuccess }));
    if (result.error) {
      if (result.payload.errorCode === 'INCORRECT_PASSWORD') {
        void message.error('Mật khẩu không chính xác')
      } else void message.error('Thay đổi mật khẩu thất bại')
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: async value => {
      await handleChangePassword({ ...value });
    },
  });

  const { setFieldValue } = formik;

  return (
    <ChangePasswordStyle className="container">
      <CardStyle title={<div className="title"><Image onClick={() => onBack?.()} src={ICON_BACK} />Thay đổi mật khẩu</div>}>
        <Form name="login" className="ChangePassword-form" layout="vertical" onFinish={() => formik.handleSubmit()}>
          <Row gutter={[16, 16]} className="wrap-form">
            <Col md={24} lg={12}>
              <PasswordField
                label="Mật khẩu cũ"
                field={formik.getFieldProps('oldPassword')}
                setFieldValue={setFieldValue}
                className="form-control form-oldPassword"
                inputProps={{
                  size: 'middle',
                  placeholder: 'Mật khẩu cũ',
                }}
                error={formik.errors.oldPassword}
                touched={formik.touched.oldPassword}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]} className="wrap-form">
            <Col md={24} lg={12}>
              <PasswordField
                label="Mật khẩu mới"
                field={formik.getFieldProps('newPassword')}
                setFieldValue={setFieldValue}
                className="form-control form-newPassword"
                inputProps={{
                  size: 'middle',
                  placeholder: 'Mật khẩu mới',
                }}
                error={formik.errors.newPassword}
                touched={formik.touched.newPassword}
              />
            </Col>
            <Col md={24} lg={12}>
              <PasswordField
                label="Nhập lại mật khẩu mới"
                field={formik.getFieldProps('confirmPassword')}
                setFieldValue={setFieldValue}
                className="form-control form-confirmPassword"
                inputProps={{
                  size: 'middle',
                  placeholder: 'Nhập lại mật khẩu mới',
                }}
                error={formik.errors.confirmPassword}
                touched={formik.touched.confirmPassword}
              />
            </Col>
          </Row>
          <hr />
          <Button className="btn-right btn-submit" htmlType="submit" width={168}>
            Lưu
          </Button>
        </Form>
      </CardStyle>
    </ChangePasswordStyle>
  );
};

export default ChangePassword;
