import React from 'react';
import { Form, Select } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';
import { FieldInputProps, FormikTouched, FormikErrors } from 'formik';
import styled from 'styled-components';

type Props = {
  label?: string;
  field: FieldInputProps<any>;
  optionsSelect: Array<{
    label: string | React.ReactElement;
    value: number | string;
    disabled?: boolean;
  }>;
  setFieldValue: (field: string, value: any) => void;
  selectProps?: SelectProps<any>;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  notFoundContent?: React.ReactNode;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
} & Omit<FormItemProps, 'children'>;

const SelectField: React.FC<Props> = ({
  label,
  touched,
  error,
  field,
  selectProps,
  optionsSelect,
  setFieldValue,
  notFoundContent,
  disabled,
  placeholder,
  required,
  defaultValue,
  ...rest
}) => {
  const onChange = (e: any): void => {
    setFieldValue(field.name, e);
  };

  return (
    <Form.Item
      colon={false}
      label={label}
      validateStatus={error && touched ? 'error' : ''}
      help={(touched && error) as string}
      {...rest}
    >
      <SelectCustomStyle>
        <Select
          {...selectProps}
          options={optionsSelect}
          onChange={onChange}
          popupClassName="custom-dropdown"
          notFoundContent={notFoundContent}
          disabled={disabled}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
      </SelectCustomStyle>
    </Form.Item>
  );
};

export default SelectField;

const SelectCustomStyle = styled.div`
  min-width: 120px;
  @media screen and (max-width: 575px) {
    min-width: 70px;
  }
  .ant-select {
    width: 100%;
    background-color: #272727;
    color: #cdc29a;
    border-color: #342c24;
    .ant-select-selector {
      background-color: #272727 !important;
      color: #cdc29a !important;;
      border-color: #342c24 !important;;
    }

    .ant-select-arrow {
      color: #cdc29a ;
    }
  }
`;
