import React from 'react';
import { NoteComponentStyle } from './noteStyle';
import { DeleteOutlined } from '@ant-design/icons';

interface NoteComponentProps {
  content: string | TrustedHTML;
  onClick: () => void;
}

const NoteComponent: React.FC<NoteComponentProps> = ({ content, onClick }) => {
  return (
    <NoteComponentStyle>
      <div className="header">
      <div className="note-content" dangerouslySetInnerHTML={{ __html: content }} />
        <div className="btn-delete" onClick={onClick}> <DeleteOutlined /> </div>
      </div>
    </NoteComponentStyle>
  );
};

export default NoteComponent;
