import React from 'react';
import { Col, Row } from 'antd';
import { BAGUA } from 'src/assets/images';
import { Image } from 'src/components';
import { useAppSelector } from 'src/stores';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import NoAppointment from '../appointment/NoAppointment';
import { WrapperNoteStyle } from 'src/components/timeLine/TimeLineStyle';

const CustomCarousel = () => {
  const { news } = useAppSelector(state => state.dashboard);
  const navigate = useNavigate();

  return (
    <WrapperNoteStyle>
      <Row gutter={[24, 24]} className='block-img-slice'>
        {news?.length > 0
          ? news?.map(item => {
            return (
              <Col xs={24} sm={12} md={8} lg={6} key={item?.id} onClick={() => navigate(`/detail-news/${item?.id}`)} style={{ cursor: 'pointer' }}>
                <div className="img-slide-3">
                  <Image src={BAGUA} />
                </div>
                <div className="block-new">
                  <div className="carousel-content">{item.title}</div>
                  <div className="car-date">{moment(item?.createdAt).format('YYYY/MM/DD')}</div>
                  <div className="car-desciption" dangerouslySetInnerHTML={{ __html: item?.content }} />
                </div>
              </Col>
            );
          })
          : <div style={{ margin: '0 auto' }}><NoAppointment content="Chưa có ghi chú nào được ghim ra ngoài" /></div>
        }
      </Row>
    </WrapperNoteStyle>
  );
};

export default CustomCarousel;
