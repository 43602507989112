/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { AppointmentStyle, CalendarContentStyle, CalendarStyle } from './AppointmentStyle';
import { CardStyle } from 'src/utils/styled';
import Calendar, { CalendarProps } from 'react-calendar';
import moment from 'moment-timezone';
import TimeSlotSelector from './timeSlotSelector/timeSlot';
import { Col, Form, message, Row } from 'antd';
import { Button, Image, Modal, TimeLine } from 'src/components';
import { ICON_DELETE } from 'src/assets/images';
import { TIME_LINE } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { createAppointmentAction, deleteAppointmentAction, getAppointmentAction } from 'src/stores/screens/appointment/appointment.action';
import NoAppointment from '../dashboard/appointment/NoAppointment';
import { busyTimeAction } from 'src/stores/screens/common/common.action';

const AppointmentScreen: React.FC = () => {
  const { isLoading, appointmentDetails } = useAppSelector(state => state.appointment);
  const { busyTime } = useAppSelector(state => state.masterData);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [delModalId, setDelModalId] = useState<string>('');
  const [timeSlots, setTimeSlots] = useState<Appointment.TimeSelectedProps[]>(
    TIME_LINE.map(time => ({ ...time, selected: false, disabled: false })),
  );
  const timeSelected = timeSlots.filter(item => item.selected)[0]?.key;
  const dateSelected = moment(selectedDate).format('YYYY-MM-DD');

  const dispatch = useAppDispatch();

  const handleDateChange: CalendarProps['onChange'] = value => {
    if (Array.isArray(value)) {
      const [startDate] = value;
      if (startDate) {
        setSelectedDate(startDate);
      }
    } else if (value) {
      setSelectedDate(value);
    } else {
      setSelectedDate(null);
    }
    setTimeSlots(TIME_LINE.map(time => ({ ...time, selected: false })));
  };

  const getBusyTimeByDate = async (): Promise<void> => {
    if (selectedDate) {
      await dispatch(busyTimeAction({ data: { startDate: moment(selectedDate).format('YYYY-MM-DD'), endDate: moment(selectedDate).format('YYYY-MM-DD') } }));
    }
  };

  useEffect(() => {
    getAppointmentByDate();
    getBusyTimeByDate()
  }, [selectedDate]);

  const onDelOpenModal = (id: string) => {
    setDelModalId(id);
  };

  const onDelCanelModal = () => {
    setDelModalId('');
  };

  const onDeleteSubmit = async () => {
    await dispatch(
      deleteAppointmentAction({
        data: { id: delModalId },
        callback: onDeleteAppointmentSuccess,
      }),
    );
    setDelModalId('');
  };

  const getAppointmentByDate = async () => {
    if (selectedDate) {
      await dispatch(
        getAppointmentAction({
          data: { requestDate: dateSelected ?? '' },
          callback: () => {},
        }),
      );
    }
  };

  const onCreateAppointmentSuccess = (): void => {
    getAppointmentByDate();
    setTimeSlots(TIME_LINE.map(time => ({ ...time, selected: false })))
    message.success('Đặt lịch hẹn thành công');
  };

  const onDeleteAppointmentSuccess = (): void => {
    getAppointmentByDate();
    message.success('Xóa lịch hẹn thành công');
  };

  const onAppointmentSubmit = async () => {
    const result: any = await dispatch(
      createAppointmentAction({
        data: { requestDate: dateSelected, requestTime: timeSelected, isRepeated: 0 },
        callback: onCreateAppointmentSuccess,
      }),
    );
    if (result?.error) {
      message.error('Đặt lịch thất bại');
    }
  };

  const checkWeekend = (date: moment.Moment) => {
    const dayOfWeek = date.day();
    if (dayOfWeek === 6) {
      return 'SATURDAY'
    }
    if (dayOfWeek === 0) {
      return 'SUNDAY'
    }
  };

  const handleBusyTimeSlots = (
    value: Appointment.TimeSelectedProps[],
    busy: Common.BusyTime[],
    appointment: Appointment.AppointmentData[]
  ) => {
    const weekendStatus = checkWeekend(moment(selectedDate));
    if (weekendStatus === 'SUNDAY') {
      return [];
    }
    if (weekendStatus === 'SATURDAY') {
      return value
        .filter(aItem => !['frame_04', 'frame_05'].includes(aItem.key))
        .map(aItem => ({
          ...aItem,
          disabled: busy.some(bItem => bItem.busyTimeFrame === aItem.key) ||
            appointment.some(req => req.requestTime === aItem.key),
        }));
    }
    return value.map(aItem => ({
      ...aItem,
      disabled: busy.some(bItem => bItem.busyTimeFrame === aItem.key) ||
        appointment.some(req => req.requestTime === aItem.key),
    }));
  };
  const originalDate = selectedDate ? moment(selectedDate) : moment();
  const formattedDate = originalDate.format('dddd, DD [tháng] MM [năm] YYYY');

  return (
    <AppointmentStyle className="container">
      <CardStyle title="Ghi chú" className="appointment">
        <Row gutter={[32, 16]}>
          <Col md={24} lg={12} span={24}>
            <CalendarStyle>
              <Calendar onChange={handleDateChange} value={selectedDate} locale="vi" className="custom-calendar" />
              <hr />
              <CalendarContentStyle className="calendar-content">
                <div className="title">{formattedDate}</div>
                <div className="title">Đặt lịch ngay để nhận tư vấn, luận giải phong thủy:</div>
                <TimeSlotSelector setTime={setTimeSlots} times={handleBusyTimeSlots(timeSlots, busyTime, appointmentDetails)} />
                <div className="title" style={{ padding: '10px 0' }}>*Người dùng chỉ được đặt tối đa 3 khung giờ một ngày</div>
                <div className="btn-submit">
                  <Button onClick={onAppointmentSubmit} disabled={!selectedDate || !timeSelected || appointmentDetails?.length >= 3} loading={isLoading}>
                    Đặt lịch ngay
                  </Button>
                </div>
              </CalendarContentStyle>
            </CalendarStyle>
          </Col>
          <Col md={24} lg={12} span={24} className="content-right">
            <Form name="login" className="login-form">
              <div className="text-white"> Lịch hẹn </div>
              {appointmentDetails?.length
                ? appointmentDetails?.map(item => {
                  return (
                  <div className="wrapper" key={item.id}>
                    <TimeLine {...item} />
                    <Button buttonType="icon" onClick={() => onDelOpenModal(item.id)}>
                      <Image src={ICON_DELETE} />
                    </Button>
                  </div>
                  );
                })
                : <NoAppointment content="Không có lịch hẹn ngày hôm nay"/>
              }
            </Form>
          </Col>
        </Row>
      </CardStyle>
      <Modal
        title="Xóa lịch hẹn"
        open={!!delModalId}
        onOk={() => onDeleteSubmit()}
        onCancel={onDelCanelModal}
        okText="Đồng ý"
        cancelText="Đóng"
      >
        <div>Bạn muốn xóa lịch hẹn này</div>
      </Modal>
    </AppointmentStyle>
  );
};

export default AppointmentScreen;
