import styled from 'styled-components';

export const AppointmentStyle = styled.div`
  height: 100%;
  .content-right {
    border-left: 1px solid #4e473e;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-submit {
    display: flex;
    justify-content: flex-end;
  }

  .text-white {
    font-size: 16px;
    color: #ffffff;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .appointment-year {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
  }
  .form-all-year {
    margin-bottom: 0px;
  }
`;

export const CalendarContentStyle = styled.div`
  .title {
    font-size: 16px;
    color: #ead38e;
  }
  .description {
    font-size: 14px;
    color: #ffffff;
  }
`;

export const CalendarStyle = styled.div`
  .custom-calendar {
    background: transparent;
    font-size: 16px;
    width: 100%;
    border: unset;
  }
  .react-calendar__year-view__months {
    color: #ffffff;
  }
  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: unset;
    }
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: unset;
  }

  .react-calendar__navigation {
    color: #ead38e;
  }

  .react-calendar__month-view__weekdays,
  .react-calendar__month-view__days__day {
    color: #ffffff;
    font-size: 16px;
  }

  .react-calendar__tile {
    background: transparent;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
  }

  .react-calendar__tile--now {
    color: yellow;
    background: transparent;
    font-weight: bold;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar__month-view__days__day button:enabled:hover {
    background: none;
    background-color: unset;
    position: relative;
    line-height: 17px;

    abbr {
      background: #ead38e;
      color: #342c24;
      border-radius: 50%;
      border: 1px solid #52c41a;
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .react-calendar__tile.react-calendar__decade-view__years__year,
  .react-calendar__tile.react-calendar__century-view__decades__decade {
    color: #ffffff;
  }

  .react-calendar__navigation button:disabled,
  .react-calendar__navigation__label {
    background-color: unset;
  }

  .react-calendar__month-view__days__day {
    height: 40px;
    width: 50px;
  }

  .react-calendar__tile:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
`;

