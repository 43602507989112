import styled from 'styled-components';

export const SettingStyle = styled.div`
  max-width: 965px;
  .content-right {
    border-left: 1px solid #4e473e;
  }

  .ant-select-selection-item {
    color: #fff !important;
  }
  .ant-form-item-no-colon {
    color: #EAD38E !important;
  }

  .text-white {
    font-size: 16px;
    color: #ffffff;
  }
  .avatar-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  } 
  .setting-form {
    .wrap-form {
      margin-top: 20px;
      .ant-col {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    .form-control {
      margin-bottom: 0px;
    }
  }
`;

