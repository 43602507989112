import { createSlice } from '@reduxjs/toolkit';
import { createAppointmentAction, deleteAppointmentAction, getAppointmentAction } from './appointment.action';

const initialState: Appointment.AppointmentState = {
  isLoading: false,
  error: null,
  appointmentDetails: [],
};

const { actions, reducer } = createSlice({
  name: 'appointment_slice',
  initialState,
  reducers: { },
  extraReducers: builder => {
    builder
      .addCase(createAppointmentAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createAppointmentAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createAppointmentAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getAppointmentAction.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(getAppointmentAction.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.appointmentDetails = action.payload?.data ?? [];
      })
      .addCase(getAppointmentAction.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error;
      })

      .addCase(deleteAppointmentAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteAppointmentAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteAppointmentAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
