import { createSlice } from '@reduxjs/toolkit';
import { deletePinedAction, getDashboardAction, getPinedAction, getNewsAction, getDetailNewsAction } from './dashboard.action';

const initialState: Appointment.DashboardState = {
  isLoading: false,
  error: null,
  dashboard: [],
  pined: [],
  news: [],
  detailNew: {}
};

const { actions, reducer } = createSlice({
  name: 'appointment_slice',
  initialState,
  reducers: { },
  extraReducers: builder => {
    builder
      .addCase(getDashboardAction.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(getDashboardAction.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.dashboard = action.payload?.data ?? [];
      })
      .addCase(getDashboardAction.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getPinedAction.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(getPinedAction.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.pined = action.payload?.data ?? [];
      })
      .addCase(getPinedAction.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getNewsAction.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(getNewsAction.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.news = action.payload?.data ?? [];
      })
      .addCase(getNewsAction.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getDetailNewsAction.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(getDetailNewsAction.fulfilled, (state, action) => {
        state.detailNew = action?.payload ?? {};
      })
      .addCase(getDetailNewsAction.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error;
      })

      .addCase(deletePinedAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePinedAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePinedAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
