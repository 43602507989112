import { createAsyncThunk } from '@reduxjs/toolkit';
import COMMON_API from './common.api';

export const busyTimeAction = createAsyncThunk<any, any>(
  'common',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const busyTime = await COMMON_API.busyTimeAPI(data);
      // callback();
      return busyTime;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
