import { LOCAL_STORAGE_KEY } from 'src/constants';
import LocalStorage from './storage';

export const setUserData = (user: Auth.MeInfo) => {
  LocalStorage.set(LOCAL_STORAGE_KEY.TOKEN, user.accessToken);
  LocalStorage.set(LOCAL_STORAGE_KEY.EMAIL, user.email);
  LocalStorage.set(LOCAL_STORAGE_KEY.ROLE, user.role);
  LocalStorage.set(LOCAL_STORAGE_KEY.USER_ID, user.id);
  LocalStorage.set(LOCAL_STORAGE_KEY.USER_NAME, user.username);
};
export const logout = () => {
  LocalStorage.remove(LOCAL_STORAGE_KEY.TOKEN);
  LocalStorage.remove(LOCAL_STORAGE_KEY.EMAIL);
  LocalStorage.remove(LOCAL_STORAGE_KEY.ROLE);
  LocalStorage.remove(LOCAL_STORAGE_KEY.USER_ID);
  LocalStorage.remove(LOCAL_STORAGE_KEY.USER_NAME);
//   authStore.logout();
//   channel.postMessage('LOGOUT');
//   history.replace('/');
};
