import React from 'react';
import { CardStyle } from 'src/utils/styled';
import { EMPTY } from 'src/assets/images';
import { Button, Image } from 'src/components';
import { useNavigate } from 'react-router-dom';

interface NoAppointmentProps {
  title?: string;
  content?: string;
}

const NoAppointmentCustom: React.FC<NoAppointmentProps> = ({ title, content, ...props }) => {
  const navigate = useNavigate();
  return (
    <CardStyle title={title ?? null} {...props}>
      <div className="content-center">
        <Image src={EMPTY} />
        <div className='content'>
          {content ??
            <>
            <div>Bạn chưa có lịch hẹn nào.</div>
            <div>Nhấn vào nút bên dưới để đặt lịch tư vấn, luận giải phong thủy đầu tiên của bạn!</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={() => navigate('/appointment')} className="btn-submit">Đặt lịch ngay bây giờ</Button>
            </div>
            </>
          }
        </div>
      </div>
    </CardStyle>
  );
};

export default NoAppointmentCustom;
