import React from 'react';
import { TagComponentStyle } from './tagStyle';

interface TimeComponentProps {
  content: Appointment.TimeSelectedProps;
  style?: any;
}

const TagComponent: React.FC<TimeComponentProps> = ({ content, style }) => {
  return (
    <TagComponentStyle
      key={content.value}
      className={`${content?.selected ? 'active' : 'default'} ${content?.disabled ? 'disabled' : ''}`}
      style={style}
    >
      {content.value}
    </TagComponentStyle>
  );
};

export default TagComponent;
