/* eslint-disable no-console */
import React from 'react';
import { Col, Form, Layout, message, Row } from 'antd';
import { Button, Image } from 'src/components';

import styled from 'styled-components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { LARGE_LOGO } from 'src/assets/images';
import { InputField } from 'src/components/form';
import { useAppDispatch } from 'src/stores';
import { verificationMailAction } from 'src/stores/screens/auth/auth.action';

const { Content } = Layout;

const ChangePasswordScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const validationSchema = yup.object().shape({
    email: yup.string().email('Email không hợp lệ').required('Email là bắt buộc')
  });

  const initialValues = {
    email: 'user@example.com'
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleVerify(value);
    }
  });
  const onSubmitSuccess = (): void => {
    navigate('/change_password', {
      state: {
        mailState: formik.getFieldProps('email').value,
      },
    });
    void message.success('Vui lòng kiểm tra hộp thư để lấy mã xác minh');
  };

  const handleVerify = (value): void => {
    void dispatch(verificationMailAction({ data: value, callback: onSubmitSuccess }));
  };

  const { setFieldValue } = formik;

  return (
    <ChangeScreenStyle className="container-full">
      <Row>
        <Col
          xs={24}
          sm={13}
          className="box-logo"
        >
          <div className="logo">
            <Image src={LARGE_LOGO} />
          </div>
          <div className="title">
            <p className="brand-name">Phong Thủy Nhật Minh</p>
            <p className="description">
              Đặt lịch tư vấn, xem quẻ, luận giải về phong thủy
            </p>
          </div>
        </Col>
        <Col xs={24} sm={11} className="box-form">
          <Form
            name="login"
            className="login-form"
            onFinish={() => formik.handleSubmit()}
          >
            <p className="title">Quên mật khẩu</p>
            <p className="sub-title">
              Để khôi phục mật khẩu, bạn vui lòng nhập email đã dùng để đăng ý
              trên hệ thống
            </p>
            <InputField
              label="Email"
              field={formik.getFieldProps('email')}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: 'middle',
                placeholder: 'Nhập email'
              }}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
            <Button className="btn-submit" htmlType="submit">
              Gửi yêu cầu
            </Button>
          </Form>
        </Col>
      </Row>
    </ChangeScreenStyle>
  );
};

export default ChangePasswordScreen;

const ChangeScreenStyle = styled(Content)`
  @import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-row {
    height: 100%;
    .ant-form-item {
      margin-bottom: 20px;
      .ant-form-item-row {
        flex-direction: column;
      }
      .ant-form-item-label > label {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        color: #ead38e;
      }
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #242424;
    .title {
      font-size: 32px;
      color: #ead38e;
      font-weight: bold;
    }
    .sub-title {
      font-size: 17px;
      color: #ffffff;
    }
  }

  .login-form {
    width: 100%;
    input.ant-input {
      /* border: none; */
    }
  }

  .btn-submit {
    width: 100%;
  }
`;
