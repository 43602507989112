import { createAsyncThunk } from '@reduxjs/toolkit';

import USER_API from './user.api';

export const getUserAction = createAsyncThunk<any>('user', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await USER_API.getUserAPI(params);

    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const updateUserAction = createAsyncThunk<any, any>(
  'user_update',
  async ({ data }, { dispatch, rejectWithValue }) => {
    try {
      const res = await USER_API.updateUserAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const changePasswordAction = createAsyncThunk<any, any>(
  'user_changep_password',
  async ({ data, callback }, { dispatch, rejectWithValue }) => {
    try {
      const res = await USER_API.changePasswordAPI(data);
      if (res) {
        callback();
      }
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
