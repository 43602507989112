/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import { Col, message, Row } from 'antd';

import NoAppointment from './NoAppointment';
import { WrapperNoteStyle } from 'src/components/timeLine/TimeLineStyle';
import { AppointmentStyle, CardStylePined } from './AppointmentStyle';
import { Image, Modal, NoteComponent } from 'src/components';
import CustomCarousel from '../cardCarousel';
import NoAppointmentCustom from './NoAppointmentCustom';
import {
  getPinedAction,
  getNewsAction,
  deletePinedAction,
} from 'src/stores/screens/dashboard/dashboard.action';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { ICON_CALANDER_DATE } from 'src/assets/images';
import { getTimeByKey } from 'src/utils';
import LocalStorage from 'src/utils/storage';
import { LOCAL_STORAGE_KEY } from 'src/constants';
import moment from 'moment-timezone';

const Appointment: React.FC = () => {
  const [delModalId, setDelModalId] = useState<string>('');
  const [dataDashboard, setDataDashboard] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { pined } = useAppSelector(state => state.dashboard);

  useEffect(() => {
    void getDasboad();
  }, []);

  useEffect(() => {
    void getPined();
  }, []);

  useEffect(() => {
    void getNews();
  }, []);

  const getNews = async () => {
    await dispatch(
      getNewsAction({
        data: { requestDate: '2022-01-01' },
        callback: () => {},
      }),
    );
  };

  const getPined = async () => {
    await dispatch(
      getPinedAction({
        data: { requestDate: '2022-01-01' },
        callback: () => {},
      }),
    );
  };

  const getDasboad = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}appointment/dashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${LocalStorage.get(LOCAL_STORAGE_KEY.TOKEN)}`,
      },
    })
      .then((rs) => rs.json())
      .then((data) => {
        setDataDashboard(data);
      });
  };

  const formatDate = (inputDate: any): string => {
    const date = new Date(inputDate);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return ` ${formattedDate}`;
  };

  const onDelOpenModal = (id: string) => {
    setDelModalId(id);
  };

  const onDelCanelModal = () => {
    setDelModalId('');
  };

  const onDeleteAppointmentSuccess = (): void => {
    void getPined();
    void message.success('Xóa ghi chú thành công');
  };

  const onDeleteSubmit = async () => {
    await dispatch(
      deletePinedAction({
        data: { id: delModalId, pinned: false },
        callback: onDeleteAppointmentSuccess,
      }),
    );
    setDelModalId('');
  };

  interface StatusInfo {
    statusClass: string;
    statusText: string;
  }

  const funcStatus = (status: string): StatusInfo => {
    const statusObject: StatusInfo = { statusClass: '', statusText: '' };
    switch (status) {
      case 'requesting':
        statusObject.statusClass = 'bock-status6';
        statusObject.statusText = 'Chờ xác nhận';
        break;
      case 'initiated':
        statusObject.statusClass = 'bock-status5';
        statusObject.statusText = 'Đã khởi tạo';
        break;
      case 'information_provided':
        statusObject.statusClass = 'bock-status2'
        statusObject.statusText = 'Đã cung cấp thông tin';
        break;
      case 'consulted':
        statusObject.statusClass = 'bock-status3';
        statusObject.statusText = 'Đã tư vấn';
        break;
      case 'documents_sent':
        statusObject.statusClass = 'bock-status1';
        statusObject.statusText = 'Đã cung cấp tài liệu';
        break;
      default:
        statusObject.statusClass = 'bock-status4';
        statusObject.statusText = 'Hoàn thành';
    }
    return statusObject;
  };

  const statusClassDate = useMemo(() => {
    return funcStatus(dataDashboard?.dateAppointment?.appointmentStatus);
  }, [dataDashboard]);

  const statusClassYear = useMemo(() => {
    return funcStatus(dataDashboard?.yearAppointment?.appointmentStatus);
  }, [dataDashboard]);

  return (
    <AppointmentStyle className="">
      <Row gutter={[32, 16]} className='custom-row'>
        {!dataDashboard?.dateAppointment && !dataDashboard?.yearAppointment && <NoAppointmentCustom />}
        {dataDashboard?.dateAppointment &&
          <div className="block-cal" >
            <div className="img-style">
              <Image src={ICON_CALANDER_DATE} />
            </div>
            <div className="text-date">{`Bạn có lịch hẹn luận giải vào ngày: ${formatDate(dataDashboard?.dateAppointment?.request?.requestDate?.toString())}, thời gian: ${
              dataDashboard?.dateAppointment?.request?.requestTime ? getTimeByKey(dataDashboard?.dateAppointment?.request?.requestTime) : ''
            }`}</div>
            <div className={statusClassDate?.statusClass}>{statusClassDate?.statusText}</div>
          </div>
        }
        {dataDashboard?.yearAppointment &&
          <div className="block-cal" >
            <div className="img-style">
              <Image src={ICON_CALANDER_DATE} />
            </div>
            <div className="text-date">{`Tháng: ${moment(dataDashboard?.yearAppointment?.request?.requestDate).format('MM/YYYY')} - Bạn đã nhận được luận giải
            `}</div>
            <div className={statusClassYear?.statusClass}>{statusClassYear?.statusText}</div>
          </div>
        }
        <Col md={24} lg={24} style={{ marginLeft: '0', marginRight: '0' }} className='custom-col'>
          <CardStylePined title="Ghi chú">
            <WrapperNoteStyle>
              <Row gutter={[16, 16]}>
                {pined?.length > 0
                  ? pined?.map(item => {
                    return (
                      <Col md={24} lg={12} span={24} key={item?.id}>
                        <NoteComponent content={item?.content} onClick={() => onDelOpenModal(item?.id)} />
                      </Col>
                    );
                  })
                  : <div style={{ margin: '0 auto' }}><NoAppointment content="Chưa có ghi chú nào được ghim ra ngoài" /></div>
                }
              </Row>
            </WrapperNoteStyle>
          </CardStylePined>
          <CustomCarousel />
        </Col>
      </Row>
      <Modal
        title="Xóa ghi chú"
        open={!!delModalId}
        onOk={() => onDeleteSubmit()}
        onCancel={onDelCanelModal}
        okText="Đồng ý"
        cancelText="Đóng"
      >
        <div>Bạn muốn xóa ghi chú này không</div>
      </Modal>
    </AppointmentStyle>
  );
};

export default Appointment;
