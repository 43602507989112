import React, { useEffect, useMemo } from 'react';
import { Avatar, Layout, Menu } from 'antd';
import styled from 'styled-components';
import { Link, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { BACKGROUND, DEFAULT_AVATAR, ICON_CALANDER, ICON_COMMENT, ICON_DASHBOARD, ICON_SETTING, LOGO } from 'src/assets/images';
import Media from 'src/utils/media';
import { Dropdown, Image } from 'src/components';
import { logout } from 'src/utils/auth';
import { useAppDispatch, useAppSelector } from 'src/stores';
import authReducer from 'src/stores/screens/auth/auth.reducer';
import { LOCAL_STORAGE_KEY } from 'src/constants';
import LocalStorage from 'src/utils/storage';
import { getUserAction } from 'src/stores/screens/user/user.action';
import ResetStateReducer from 'src/stores/resetStateReducer';

const { Header, Content } = Layout;
const PrivateLayout: React.FC = () => {
  const outlet = useOutlet();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = LocalStorage.get(LOCAL_STORAGE_KEY.TOKEN);
  const username = LocalStorage.get(LOCAL_STORAGE_KEY.USER_NAME);
  const { user } = useAppSelector(state => state.user);
  const MENU = [
    {
      key: 'DASHBOARD',
      icon: <Image src={ICON_DASHBOARD} />,
      label: <Link to="/dashboard">Dashboard</Link>,
      path: '/dashboard',
    },
    {
      key: 'APPOINTMENT',
      icon: <Image src={ICON_CALANDER} />,
      label: <Link to="/appointment">Đặt lịch</Link>,
      path: '/appointment',
    },
    {
      key: 'CONSULTATION',
      icon: <Image src={ICON_COMMENT} />,
      label: <Link to="/consultation">Luận giải</Link>,
      path: '/consultation',
    },
    {
      key: 'SETTING',
      icon: <Image src={ICON_SETTING} />,
      label: <Link to="/setting">Cài đặt</Link>,
      path: '/setting',
    },
  ];

  const selectedMenu = useMemo(() => {
    return MENU.find(item => item.path === location.pathname || item.path === '/')?.key ?? '';
  }, [location.pathname]);

  const handleLogoutClick = () => {
    void dispatch(authReducer.resetMeInfo());
    logout();
    navigate('/login');
  };

  const handleChangePassClick = () => {
    navigate('/setting', { state: { changePasss: true } });
  };

  const getUserProfile = (): void => {
    void dispatch(getUserAction());
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const items = [
    {
      key: '1',
      label: <div className="lable">Đăng xuất</div>,
      onClick: handleLogoutClick,
    },
    {
      key: '2',
      label: <div className="lable">Đổi mật khẩu</div>,
      onClick: handleChangePassClick,
    },
  ];
  return (
    <PrivateLayoutStyle>
      {/* <Sider className="layout-sider" breakpoint="lg" collapsedWidth="0" onBreakpoint={_ => {}} onCollapse={_ => {}}>
        <div className="logo">LOGO</div>
      </Sider> */}
      <ResetStateReducer />
      <Layout className="layout-body">
        <Header className="body-header">
          <HeaderStyle>
            <Link to="/">
              <Image className="logo" src={LOGO} />
            </Link>
            <UserInfoStyle>
              {token && (
                <div className="header-info">
                  {/* <Image src={ICON_QUESTION} />
                  <Image src={ICON_BELL} /> */}
                  <Dropdown
                    trigger={
                      <div className="user-name">
                        <Avatar src={`${user?.avatar ? `data:image/png;base64, ${user?.avatar}` : DEFAULT_AVATAR}`} />
                        <div> {username} </div>
                      </div>
                    }
                    items={items}
                    placement="bottomRight"
                  />
                </div>
              )}
              {/* <Link to={'/login'} onClick={handleLogoutClick}>
                Go to login
              </Link> */}
            </UserInfoStyle>
          </HeaderStyle>
        </Header>
        <MenuStyle>
          <Menu className="layout-menu" mode="horizontal" defaultSelectedKeys={[selectedMenu]} items={MENU} />
        </MenuStyle>

        <Content className="body-content">{outlet}</Content>

        {/* <Footer className="body-footer">footer</Footer> */}
      </Layout>
    </PrivateLayoutStyle>
  );
};

export default PrivateLayout;

const PrivateLayoutStyle = styled(Layout)`
  min-height: 100vh;
  width: 100%;

  .layout-body {
    background: #222222;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    .body-header {
      height: 48px;
      padding: 0;
      background-color: transparent;
      box-shadow: 1px 1px 1px lightblue;
      .logo {
        display: flex;
        align-items: center;
      }
    }
    .body-content {
      width: 100%;
      margin: 0 auto;
      padding: 20px 80px;
      ${Media.lessThan(Media.SIZE.XL)} {
        max-height: 100%;
      }
      /* background-image: url(${BACKGROUND}); */
    }

    .body-footer {
      text-align: center;
    }
    ${Media.lessThan(Media.SIZE.MD)} {
      .body-content {
        padding: 20px 0px;
      }
    }
  }
`;

const HeaderStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(197.35deg, #2d2621 18.51%, #261d12 94.71%);
  padding: 0 30px;
  align-items: center;
`;

const UserInfoStyle = styled.div`
  /* height: 100%;
  width: 100%; */
  text-align: right;
  .header-info {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .lable {
      height: 18px;
    }
    .ant-image {
      height: 48px;
    }
    img {
      cursor: pointer;
    }
    .user-name {
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 700;
      font-size: 14px;
      color: #ead38e;
      cursor: pointer;
      margin-top: 10px;
    }
  }
`;

const MenuStyle = styled.div`
  max-width: 100vw;
  overflow: hidden;
  .layout-menu {
    padding: 0 30px;
    background: #1d1b18;
    border-bottom: 1px solid rgb(67 61 61);
    border-top: 1px solid rgb(67 61 61);
    .ant-menu-item .ant-menu-item-icon {
      color: rgba(234, 211, 142, 1);
    }
    .ant-menu-item {
      display: flex;
      justify-content: center;
      .ant-menu-title-content {
        a {
          color: rgba(234, 211, 142, 1);
        }
      }
    }
    .ant-menu-item-selected {
      .ant-menu-item-icon {
        color: #ffffff;
        .ant-image {
          img {
            filter: grayscale(1);
          }
        }
      }
      .ant-menu-title-content {
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    .ant-menu-item:hover {
      .ant-menu-item-icon {
        color: #ffffff;
      }
      a {
        color: #ffffff;
        text-decoration: none;
      }
    }

    .ant-menu-item-selected::after,
    .ant-menu-item:hover::after {
      border-bottom: none;
    }
  }
`;
