import React from 'react';
import { Form, Radio } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio';
import { FieldInputProps, FormikTouched, FormikErrors } from 'formik';
import styled from 'styled-components';

export type RadioGroupFieldProps = {
  field: FieldInputProps<any>;
  optionsSelect: Array<{ label: string; value: number | string }>;
  label?: string;
  radioProps?: RadioGroupProps;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  onChange?: (e: RadioChangeEvent) => void;
  disabled?: boolean;
} & Omit<FormItemProps, 'children'>;

const RadioStyle = styled.div`
  .ant-radio-wrapper-in-form-item {
    color: #EAD38E;
  }
  .ant-radio-inner::after {
    background-color: #1D1B18;
  }
  .ant-radio-checked .ant-radio-inner {
    background-color: #EAD38E;
    border-color: #EAD38E;
  }
`

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
  label,
  touched,
  error,
  field,
  radioProps,
  optionsSelect,
  disabled,
  onChange,
  ...rest
}) => {
  return (
    <RadioStyle>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={error && touched ? 'error' : ''}
        help={(touched && error) as string}
        {...rest}
      >
        <Radio.Group {...field} {...radioProps} options={optionsSelect} onChange={onChange} disabled={disabled} />
      </Form.Item>
    </RadioStyle>
  );
};

export default RadioGroupField;
