/* eslint-disable multiline-ternary */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { InputField, SelectField } from 'src/components/form';
import { SettingStyle } from './SettingStyle';
import { CardStyle } from 'src/utils/styled';
import AvatarUploader from './uploadAvatar';
import 'react-calendar/dist/Calendar.css';
import { Button, TagCustom } from 'src/components';
import ChangePassword from './changePassword/ChangePassword';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { getUserAction, updateUserAction } from 'src/stores/screens/user/user.action';
import moment from 'moment-timezone';
import { getLabelByValue } from 'src/utils';
import { useLocation } from 'react-router-dom';

const SettingScreen: React.FC = () => {
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<any>();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.user);
  const location = useLocation();
  const { changePasss } = location?.state || {};
  const validationSchema = yup.object().shape({
    username: yup.string().required('Họ tên là bắt buộc'),
    email: yup.string().email().required('Email là bắt buộc'),
    phoneNumber: yup.string().required('Số điện thoại là bắt buộc').matches(/^\d+$/, 'Số điện thoại không hợp lệ'),
    // dateOfBirth: yup.date().nullable().required('Ngày sinh dương lịch là bắt buộc'),
    dayOfBirthLunar: yup.string().required('Ngày sinh âm lịch là bắt buộc'),
    monthOfBirthLunar: yup.string().required('Ngày sinh âm lịch là bắt buộc'),
    yearOfBirthLunar: yup.string().required('Ngày sinh âm lịch là bắt buộc'),
  });
  const initialValues = {
    username: user?.username ?? '',
    email: user?.email ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    // dateOfBirth: user?.dateOfBirth ?? '',
    dayOfBirthLunar: moment(user?.dateOfBirthLunar).date().toString() ?? '',
    monthOfBirthLunar: (moment(user?.dateOfBirthLunar).month() + 1).toString() ?? '',
    yearOfBirthLunar: moment(user?.dateOfBirthLunar).year().toString() ?? '',
  };

  const getLunarYearName = (year: number): string => {
    const thienCan: string[] = ['Giáp', 'Ất', 'Bính', 'Đinh', 'Mậu', 'Kỷ', 'Canh', 'Tân', 'Nhâm', 'Quý'];
    const diaChi: string[] = ['Tý', 'Sửu', 'Dần', 'Mão', 'Thìn', 'Tỵ', 'Ngọ', 'Mùi', 'Thân', 'Dậu', 'Tuất', 'Hợi'];

    const canIndex: number = (year + 6) % 10;
    const chiIndex: number = (year + 8) % 12;
    const can: string = thienCan[canIndex];
    const chi: string = diaChi[chiIndex];
    return `${can} ${chi}`;
  };

  const currentYear: number = new Date().getFullYear();

  const lunarYears: Array<{ value: number; label: string }> = Array.from(
    { length: 100 },
    (_, i) => currentYear - i,
  ).map((year: number) => ({
    value: year,
    label: `${getLunarYearName(year)} (${year})`,
  }));
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: async (value: Auth.UserProfileRequestData) => {
      await handleUpdateProfile(value);
    },
  });
  const { setFieldValue } = formik;

  const handleUpdateProfile = async (value: Auth.UserProfileRequestData) => {
    const yearOfBirthLunar = value.yearOfBirthLunar ?? '';
    const monthOfBirthLunar = value.monthOfBirthLunar ?? '';
    const dayOfBirthLunar = value.dayOfBirthLunar ?? '';
    const result: any = await dispatch(
      updateUserAction({
        data: {
          username: value.username,
          email: value.email,
          phoneNumber: value.phoneNumber,
          dateOfBirthLunar: `${yearOfBirthLunar}-${monthOfBirthLunar}-${dayOfBirthLunar}`,
        },
      }),
    );
    if (result?.error) {
      void message.error('Cập nhật hồ sơ người dùng thất bại')
    } else {
      void message.success('Cập nhật hồ sơ người dùng thành công')
    }
  };

  const getUserProfile = (): void => {
    void dispatch(getUserAction());
  };

  useEffect(() => {
    getUserProfile();
  }, [imageUrl]);

  useEffect(() => {
    if (changePasss) {
      setIsChangePassword(true);
    }
  }, [changePasss]);

  return (
    <SettingStyle className="container">
      {isChangePassword ? (
        <ChangePassword onBack={() => setIsChangePassword(false)} />
      ) : (
        <CardStyle title="Hồ sơ người dùng">
          <Form name="login" className="setting-form" layout="vertical" onFinish={() => formik.handleSubmit()}>
              <div className="avatar-wrapper">
                <AvatarUploader image={`data:image/png;base64, ${imageUrl ?? user?.avatar}`} onChangeFile={setImageUrl} />
                <div> {user?.attribute && <TagCustom className={user?.attribute}>{getLabelByValue(user?.attribute)}</TagCustom>}</div>
            </div>
              <Row gutter={[16, 16]} className="wrap-form">
              <Col md={24} lg={12} span={24}>
                <InputField
                  label="Họ và tên"
                  field={formik.getFieldProps('username')}
                  setFieldValue={setFieldValue}
                  className="form-control form-username"
                  inputProps={{
                    size: 'middle',
                    placeholder: 'Họ và tên',
                  }}
                  error={formik.errors.username}
                  touched={formik.touched.username}
                />
                <InputField
                  label="Số điện thoại"
                  field={formik.getFieldProps('phoneNumber')}
                  setFieldValue={setFieldValue}
                  className="form-control form-phone"
                  inputProps={{
                    size: 'middle',
                    placeholder: 'Số điện thoại',
                  }}
                  error={formik.errors.phoneNumber}
                  touched={formik.touched.phoneNumber}
                />
                {/* <DatepickerField
                  label="Ngày Sinh (lịch âm)"
                  field={formik.getFieldProps('dayOfBirthLunar')}
                  setFieldValue={setFieldValue}
                  className="form-control form-dayOfBirthLunar"
                  error={formik.errors.dayOfBirthLunar}
                  touched={formik.touched.dayOfBirthLunar}
                /> */}
              </Col>
              <Col md={24} lg={12} span={24}>
                <InputField
                  label="Email"
                  field={formik.getFieldProps('email')}
                  setFieldValue={setFieldValue}
                  className="form-control form-email"
                  inputProps={{
                    size: 'middle',
                    placeholder: 'Username',
                  }}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />
                {user && (
                    <div style={{ width: '100%', display: 'flex', gap: '10px' }}>
                    <SelectField
                      label="Ngày"
                      field={formik.getFieldProps('dayOfBirthLunar')}
                      className="form-control form-dayOfBirthLunar"
                      setFieldValue={setFieldValue}
                      optionsSelect={[
                        { label: '1', value: '01' },
                        { label: '2', value: '02' },
                        { label: '3', value: '03' },
                        { label: '4', value: '04' },
                        { label: '5', value: '05' },
                        { label: '6', value: '06' },
                        { label: '7', value: '07' },
                        { label: '8', value: '08' },
                        { label: '9', value: '09' },
                        { label: '10', value: '10' },
                        { label: '11', value: '11' },
                        { label: '12', value: '12' },
                        { label: '13', value: '13' },
                        { label: '14', value: '14' },
                        { label: '15', value: '15' },
                        { label: '16', value: '16' },
                        { label: '17', value: '17' },
                        { label: '18', value: '18' },
                        { label: '19', value: '19' },
                        { label: '20', value: '20' },
                        { label: '21', value: '21' },
                        { label: '22', value: '22' },
                        { label: '23', value: '23' },
                        { label: '24', value: '24' },
                        { label: '25', value: '25' },
                        { label: '26', value: '26' },
                        { label: '27', value: '27' },
                        { label: '28', value: '28' },
                        { label: '29', value: '29' },
                        { label: '30', value: '30' },
                      ]}
                      placeholder={'Ngày'}
                      defaultValue={moment(user?.dateOfBirthLunar).date().toString()}
                      error={formik.errors.dayOfBirthLunar}
                      touched={formik.touched.dayOfBirthLunar}
                      required
                    />
                    <div style={{ minWidth: '60px', marginBottom: '10px' }}>
                      <SelectField
                        label="Tháng"
                        field={formik.getFieldProps('monthOfBirthLunar')}
                        optionsSelect={[
                          { label: '1', value: '01' },
                          { label: '2', value: '02' },
                          { label: '3', value: '03' },
                          { label: '4', value: '04' },
                          { label: '5', value: '05' },
                          { label: '6', value: '06' },
                          { label: '7', value: '07' },
                          { label: '8', value: '08' },
                          { label: '9', value: '09' },
                          { label: '10', value: '10' },
                          { label: '11', value: '11' },
                          { label: '12', value: '12' },
                        ]}
                        className="form-control form-monthOfBirthLunar"
                        setFieldValue={setFieldValue}
                        placeholder={'Tháng'}
                        defaultValue={(moment(user?.dateOfBirthLunar).month() + 1).toString()}
                        error={formik.errors.monthOfBirthLunar}
                        touched={formik.touched.monthOfBirthLunar}
                      />
                    </div>
                    <div style={{ minWidth: '150px' }}>
                      <SelectField
                        label="Năm"
                        field={formik.getFieldProps('yearOfBirthLunar')}
                        optionsSelect={lunarYears}
                        className="form-control form-yearOfBirthLunar"
                        setFieldValue={setFieldValue}
                        placeholder={'Năm'}
                        defaultValue={`${getLunarYearName(moment(user?.dateOfBirthLunar).year())}  (${moment(
                          user?.dateOfBirthLunar,
                        ).year()})`}
                        error={formik.errors.yearOfBirthLunar}
                        touched={formik.touched.yearOfBirthLunar}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <hr />
              <Button className="btn-right btn-submit" htmlType="submit" width={168} disabled={!formik.dirty || formik.isSubmitting}>
              Lưu
            </Button>
          </Form>
        </CardStyle>
      )}
    </SettingStyle>
  );
};

export default SettingScreen;
