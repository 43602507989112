/* eslint-disable no-console */
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Form, message, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ChatDetailStyle, FormEditerStyle } from './ChatSectionStyle';
import { DashOutlined } from '@ant-design/icons';
import { QuillEditor } from 'src/components/form';
import { useFormik } from 'formik';
import { Dropdown, Image } from 'src/components';
import { DEFAULT_AVATAR, ICON_PIN, ICON_PIN_ACTIVE, ICON_UNPIN } from 'src/assets/images';
import { useAppDispatch, useAppSelector } from 'src/stores';
import {
  createConsultationAction,
  getConsultationsAction,
  pinConsultationAction,
} from 'src/stores/screens/consultation/consultation.action';
import { dateTimeForChat } from 'src/utils';
import { SpinStyle } from 'src/components/style';
import { useParams } from 'react-router-dom';
import NoAppointment from '../../dashboard/appointment/NoAppointment';

const ChatDetail: React.FC = () => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const infiniteScrollRef = useRef(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.consultation);
  const { user } = useAppSelector(state => state.user);
  const { messages } = useAppSelector(state => state.consultation);
  const { detailId } = useParams();
  const appointmentId = Number(detailId);

  const initialValues = {
    chatBox: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async value => {
      await onSubmit(value.chatBox);
    },
  });
  const { setFieldValue } = formik;

  useEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setHeight(divRef.current.offsetHeight);
      }
    };
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
      updateHeight();
    }
    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  const getConsultationDetail = async (params: any) => {
    await dispatch(getConsultationsAction({ id: params.id, params: { page: params.page } }));
  };

  const onCreateConsultationSuccess = async () => {
    if (appointmentId) {
      await getConsultationDetail({ id: appointmentId });
    }
    void setFieldValue('chatBox', '');
  };

  const onSubmit = async (value: string) => {
    if (appointmentId) {
      await dispatch(
        createConsultationAction({
          data: { content: value, appointmentId },
          callback: onCreateConsultationSuccess,
        }),
      );
    }
  };

  const pinConsultation = async (id: number) => {
    const result: any = await dispatch(pinConsultationAction({ data: { id, pinned: true } }));
    if (result?.payload.errorCode) {
      void message.error('Ghim nội dung thất bại');
    } else {
      await getConsultationDetail({ id: appointmentId });
      void message.success('Ghim nội dung thành công');
    }
  };

  const unpinConsultation = async (id: number) => {
    const result: any = await dispatch(pinConsultationAction({ data: { id, pinned: false } }));
    if (result?.payload.errorCode) {
      void message.error('Bỏ ghim nội dung thất bại');
    } else {
      await getConsultationDetail({ id: appointmentId });
      void message.success('Bỏ ghim nội dung thành công');
    }
  };

  const loadMoreData = async () => {
    if (appointmentId) {
      await getConsultationDetail({ id: appointmentId, page: Number(messages.page) + 1 });
    }
  };

  useEffect(() => {
    if (lastMessageRef.current && Number(messages.page) === 1) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [messages?.data]);

  return (
    <ChatDetailStyle height={height}> {(_.isEmpty(messages?.data) && !isLoading)
      ? (
        <NoAppointment content="Không có dữ liệu" />
        )
      : (
      <div
        className="chat-content"
        id="scrollableDiv"
        style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >

          <InfiniteScroll
            ref={infiniteScrollRef}
            dataLength={messages?.data.length}
            next={loadMoreData}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            inverse={true}
            hasMore={messages?.data.length < messages?.total}
            loader={<Skeleton paragraph={{ rows: 1 }} />}
            endMessage={null}
            scrollableTarget="scrollableDiv"
          >
            <SpinStyle spinning={isLoading} wrapperClassName="root-spin" className="root-spin-component">
              {_.map(messages?.data, (message: Consultation.ConsultationDetai, index: number) => (
                <div
                  key={message.id}
                  className={`message-item ${message?.sender?.id === user?.id ? '' : 'active'} ${index === messages?.data.length - 1 ? 'last-item' : ''}`}
                  ref={index === messages?.data.length - 1 ? lastMessageRef : null}
                >
                  <Avatar src={`${message?.sender?.id === user?.id && user?.avatar ? `data:image/png;base64, ${user?.avatar}` : DEFAULT_AVATAR}`} />
                  <div className="wrap-content">
                    <div className="wapper">
                      <div className={`content ${!message.pinned ? '' : 'pin-active'}`} dangerouslySetInnerHTML={{ __html: message.content ?? '' }} />
                      {!message.pinned
                        ? <></>
                        : <div className="pin">
                          <Image src={ICON_PIN_ACTIVE} />
                        </div>}
                      <div className="timestamp">{message.createdAt ? dateTimeForChat(message.createdAt) : ''}</div>
                      {message?.sender?.id !== user?.id && (
                        <div className="btn-option">
                          <Dropdown
                            trigger={<DashOutlined style={{ fontSize: '16px', color: '#ffffff' }} />}
                            items={!message.pinned
                              ? [
                                  {
                                    key: '1',
                                    label: (
                                  <div className="lable">
                                    <Image src={ICON_PIN} />
                                    Ghim
                                  </div>
                                    ),
                                    onClick: () => pinConsultation(message.id),
                                  }
                                ]
                              : [
                                  {
                                    key: '2',
                                    label: (
                                  <div className="lable">
                                    <Image src={ICON_UNPIN} /> Bỏ ghim
                                  </div>
                                    ),
                                    onClick: () => unpinConsultation(message.id),
                                  },
                                ]}
                            placement="bottomLeft"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </SpinStyle>
          </InfiniteScroll>
      </div>
        )}
      <FormEditerStyle ref={divRef}>
        <Form name="login" className="chat-box-form" layout="vertical" onFinish={() => formik.handleSubmit()}>
          <QuillEditor
            // label="chatBox"
            field={formik.getFieldProps('chatBox')}
            setFieldValue={setFieldValue}
            className="form-control chat-box"
            touched={formik.touched.chatBox}
            placeholder="Nhập văn bản..."
            disable={isLoading}
          />
        </Form>
      </FormEditerStyle>
    </ChatDetailStyle>
  );
};

export default ChatDetail;
