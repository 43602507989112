import styled from 'styled-components';

export const NoteComponentStyle = styled.div`

  padding: 10px;
  gap: 10px;
  border: 2px solid #342c24;
  width: 100%;
  height: 100%;
  left: 16px;
  top: 76px;
  background: #ead38e;
  border-radius: 8px;
  .note-content {
    img {
      width: 100%;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .btn-delete {
        cursor: pointer;
    } 
    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      text-align: left;
    }
    .wrapper-content {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
    }
  }
`;
