import { Tag } from 'antd';
import styled from 'styled-components';

export const TagComponentStyle = styled(Tag)`
  && {
    cursor: pointer;
    background: rgb(34, 32, 30);
    color: #ead38e;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
  }
  &&.active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    gap: 4px;
    background: #ead38e;
    border-radius: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #342c24;
  }
  &&.default {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    gap: 4px;
    border: 1px solid #ead38e;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  &&.disabled {
    background: #4e473e;
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.ant-tag {
    font-size: 14px;
    padding: 4px 10px;
    ${props =>
      props.color === 'success' &&
      `
    `}
    &.moc {
      background-color: #033903;
      border: 1px solid #52c41a;
      color: #52c41a;
    }
    &.kim {
      background-color: #515115;
      border: 1px solid #ffff00;
      color: #ffff00;
    }
    &.thuy {
      background-color: #0b294b;
      border: 1px solid #40a9ff;
      color: #40a9ff;
    }
    &.hoa {
      background-color: #350c0e;
      border: 1px solid #d9363e;
      color: #d9363e;
    }
    &.tho {
      background-color: rgb(49 36 13);
      color: rgb(181 127 19);
      border: 1px solid rgb(181 127 19);
    }
  }
`;
