import Media from 'src/utils/media';
import styled from 'styled-components';

interface StyleProps {
  height: number;
}

export const ChatSectionStyle = styled.div`
  .message-item {
    padding: 10px;
    cursor: pointer;
    padding: 8px;
    gap: 16px;
    height: 52px;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    align-items: center;
    width: 100%;

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.41px;
      color: #efd485;
      text-align: left;
    }

    .timestamp {
      margin-left: auto;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-align: left;
      color: #cdc29a;
    }
    &.active {
      background: #efd485;
      border-radius: 4px;
      .title {
        color: #342c24;
      }
      .timestamp {
        color: #745812;
      }
    }
  }
`;

export const FormEditerStyle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const ChatDetailStyle = styled.div<StyleProps>`
  height: calc(100vh - 220px);
  position: relative;
  ${Media.lessThan(Media.SIZE.MD)} {
    height: calc(50vh - 110px);
  }
  .chat-content {
    height: ${({ height }) => `calc(100vh - 220px - ${height}px)`};
    ${Media.lessThan(Media.SIZE.MD)} {
      height: ${({ height }) => `calc(50vh - 110px - ${height}px)`};
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #745811;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #715e2d;
    }
  }

  .message-item {
    &.last-item {
      padding-bottom: 30px;
    }
    flex-direction: row-reverse;
    padding: 10px;
    margin-bottom: 24px;
    cursor: pointer;
    padding: 8px;
    gap: 16px;
    height: 52px;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    float: inline-end;
    .wrap-content {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.41px;
      color: #ead38e;
      text-align: left;
      position: relative;
      width: fit-content;
      .wapper {
        position: relative;
        width: fit-content;
        .btn-option {
          position: absolute;
          right: -30px;
          top: 10px;
        }
      }
      .content {
        color: #ead38e;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.75px;
        text-align: left;
        padding: 6px 16px 6px 16px;
        gap: 10px;
        border-radius: 10px;
        border: 1px solid #ead38e;
        opacity: 0px;
        width: fit-content;
        p {
          margin-bottom: 0px;
        }
        img {
          max-width: 300px;
          ${Media.lessThan(Media.SIZE.MD)} {
            max-width: 180px;
          }
        }
      }
      .pin-active {
        padding-right: 30px;
      }
      .pin {
        position: absolute;
        right: 10px;
        top: 6px;
      }
      .timestamp {
        position: absolute;
        bottom: -20px;
        right: 0px;
        color: #745812;
        width: max-content;
      }
    }
    &.active {
      flex-direction: unset;
      .content {
        color: #342c24;
        background: #efd485;
        border-radius: 20px;
      }
      .timestamp {
        right: 0px;
        left: 0px;
      }
    }
  }
`;
