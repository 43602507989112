/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import CONSULTATION_API from './consultation.api';

export const getConsultationsAction = createAsyncThunk<any, any>(
  'consultation',
  async ({ id, params }, { dispatch, rejectWithValue }) => {
    try {
      const result = await CONSULTATION_API.getConsultationsAPI(id, params);
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const getApprovedRequestAction = createAsyncThunk<any, any>(
  'approved_request',
  async ({ params, callback }, { dispatch, rejectWithValue }) => {
    try {
      const result = await CONSULTATION_API.getApprovedRequestAPI(params);
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const createConsultationAction = createAsyncThunk<any, any>(
  'create_consultation',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await CONSULTATION_API.createConsultationAPI(data);
      callback();
      return result;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const pinConsultationAction = createAsyncThunk<any, any>(
  'pin_consultation',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await CONSULTATION_API.pinConsultationAPI(data);
      callback();
      return result;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
