import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from 'src/stores/app';
import { reducer as appointmentReducer } from './screens/appointment/appointment.reducer';
import { reducer as authReducer } from './screens/auth/auth.reducer';
import { reducer as commonReducer } from './screens/common/common.reducer';
import { reducer as consultationReducer } from './screens/consultation/consultation.reducer';
import { reducer as dashboardReducer } from './screens/dashboard/dashboard.reducer';
import { reducer as userReducer } from './screens/user/user.reducer';

export const reducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  masterData: commonReducer,
  appointment: appointmentReducer,
  dashboard: dashboardReducer,
  consultation: consultationReducer,
});
