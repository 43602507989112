/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { createConsultationAction, getApprovedRequestAction, getConsultationsAction, pinConsultationAction } from './consultation.action';

const initialState: Consultation.ConsultationState = {
  isLoading: false,
  error: null,
  consultations: {
    total: 0,
    page: 1,
    limit: 10,
    data: [],
  },
  messages: {
    total: 0,
    page: 1,
    limit: 10,
    data: [],
  },
};

const { actions, reducer } = createSlice({
  name: 'consultation_slice',
  initialState,
  reducers: {
    resetMessages: state => {
      state.messages = initialState.messages;
    },
    resetConsultations: state => {
      state.consultations = initialState.consultations;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getConsultationsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getConsultationsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload?.page === 1) {
          state.messages = {
            total: action.payload?.total,
            page: action.payload?.page,
            limit: action.payload?.limit,
            data: action.payload?.data.reverse(),
          };
        } else {
          state.messages = {
            total: action.payload?.total,
            page: action.payload?.page,
            limit: action.payload?.limit,
            data: [...action.payload?.data.reverse(), ...state.messages?.data],
          };
        }
      })
      .addCase(getConsultationsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getApprovedRequestAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getApprovedRequestAction.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log(action.payload)
        if (Number(action.payload?.page) === 1) {
          state.consultations = {
            total: action.payload?.total,
            page: action.payload?.page,
            limit: action.payload?.limit,
            data: action.payload?.data,
          };
        } else {
          state.consultations = {
            total: action.payload?.total,
            page: action.payload?.page,
            limit: action.payload?.limit,
            data: [...state.consultations?.data, ...action.payload?.data],
          };
        }
      })
      .addCase(getApprovedRequestAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(createConsultationAction.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(createConsultationAction.fulfilled, (state, action) => {
        // state.isLoading = false;
      })
      .addCase(createConsultationAction.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error;
      })

      .addCase(pinConsultationAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(pinConsultationAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(pinConsultationAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
